export default class RequestParser {
    constructor({
        moduleFiles,
    }) {
        // this.URLMapPath = URLMapPath
        // this.extraAdapter = extraAdapter

        this.moduleMap = this._makeModuleMap(moduleFiles)
    }

    get(path) {
        return this.moduleMap[path]
    }

    _makeModuleMap(moduleFiles) {
        // webpackFiles const moduleFiles = require.context('@req/modules', true, /\.js$/)
        const isWebpackFiles = Object.prototype.toString.call(moduleFiles.keys) === '[object Function]'

        return (isWebpackFiles ? moduleFiles.keys() : Object.keys(moduleFiles)).reduce((modules, modulePath) => {
            const moduleName = modulePath.replace(/.js|\/|\./g, '')
            const module = isWebpackFiles ? moduleFiles(modulePath).default : moduleFiles[modulePath]

            if (module) {
                if (Array.isArray(module)) {
                    module.forEach(item => {
                        const info = this.parseExpr(item)
                        modules[`${moduleName}/${info.name}`] = info
                    })
                } else {
                    Object.keys(module).forEach(key => modules[`${moduleName}/${key}`] = this.parseExpr(module[key], key))
                }
            }
            return modules
        }, {})
    }

    parseExpr(expr, showName) {
        if (!expr) return undefined
        if (Object.prototype.toString.call(expr) === '[object Function]') return expr
        const {
            tags,
            expr: pair
        } = this.getTags(expr)
        let [name, url] = /@/.test(pair) ? pair.split('@') : [pair.split('/').pop(), pair]

        return {
            name: showName || name,
            url,
            tags
        }
    }

    getTags(expr, autoReplace = true) {
        let tags = undefined
        // console.log(expr)
        const exprReg = /^(\S)+ /
        if (/^(\S)+ /.test(expr)) { // 需要额外处理自定义符号
            let result = expr.match(exprReg)[0].trim()
            // 开头包含 & 符号说明前面有过 GET POST 需要去掉
            if (/^&/.test(result)) result = result.slice(1)
            tags = result.split('&')
        } else {
            tags = ['POST']
        }

        return {
            tags,
            expr: autoReplace ? expr.replace(/^((\S)* )/, '') : expr
        }
    }
}