<template>
    <div class="aircraft-number-item" @click="$emit('handleClick', data)">
        <time-line :item="data"></time-line>
        <div class="seat-information">
            <div class="aircraft-tips">
                <span class="line">{{ data.food == 1 ? '有餐食' : '无餐食' }}</span>
                <span class="line" v-if="data.craft">{{ data.craft | craftName }}</span>
                <span class="line" v-if="data.punctualRate">{{ data.punctualRate }}%准点率</span>
                <span
                    class="line"
                    :style="{
                        color: item.canOrder == 1 ? '#29d07c' : 'rgba(22, 43, 64, 0.15)'
                    }"
                    v-for="(item, index) in data.seatList"
                    :key="index"
                    >{{ item.seatName }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import TimeLine from './TimeLine.vue'
export default {
    props: {
        data: {}
    },
    components: {
        TimeLine
    },
    filters: {
        craftName(val) {
            if (!val) return
            if (val[0] == '3') {
                return `空客${val}`
            } else if (val[0] == 7) {
                return `波音${val}`
            } else {
                return val
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.aircraft-number-item {
    display: flex;
    flex-direction: column;
    width: 14.73rem;
    min-height: 4.59rem;
    margin: 0 auto;
    margin-top: 0.41rem;
    padding: 0.57rem 0.57rem 0rem 0.57rem;
    background: #ffffff;
    border-radius: 0.41rem;

    .seat-information {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        .aircraft-tips {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0.1rem 0;
            .line {
                display: flex;
                position: relative;
                font-size: 0.49rem;
                padding: 0.05rem 0.39rem;

                color: rgba(22, 43, 64, 0.65);
                > span {
                    margin-left: 0.21rem;
                    font-size: 0.49rem;
                    color: rgba(22, 43, 64, 0.65);
                }

                &::after {
                    position: absolute;
                    top: 0.15rem;
                    right: 0rem;
                    content: '';
                    width: 0.02rem;
                    height: 0.49rem;
                    background: #dcdee0;
                }
            }
        }
    }
}
</style>