import App from './App.vue'
import router from './router'
import store from './store'
import GATRequest from '@utils/request'
import '@styles/index.scss'
import '@styles/font.css'
import 'animate.css';
import WechatUtils from '@utils/wechat'

Vue.config.productionTip = false
const wechatUtils = new WechatUtils()
Vue.prototype.$wx = wechatUtils
Vue.use(GATRequest)
Vue.use(vant.Lazyload, {
    lazyComponent: true,
})
Vue.prototype.$showLoading = (_) => store.commit('showLoading')
Vue.prototype.$hideLoading = (_) => store.commit('hideLoading')
setTimeout((_) => {
    const node = document.querySelector('.wrap')
    if (node) {
        const rect = node.getBoundingClientRect()
        store.commit('setContentLeft', rect.left + 'px')
    } else {
        store.commit('setContentLeft', '0')
    }
    window.addEventListener('resize', (_) => {
        const rect = node.getBoundingClientRect()
        store.commit('setContentLeft', rect.left + 'px')
    })
}, 0)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
