<template>
  <div class="address-time">
    <div class="address">
      <div
        ref="start"
        :class="[
          'address-item van-ellipsis text-left animate__animated',
          isChange ? 'animate__lightSpeedInRight' : '',
        ]"
        @click="$emit('start')"
      >
        {{ start || "出发地" }}
      </div>
      <img
        src="@assets/xchange.png"
        :class="['animate__animated', isChange ? 'animate__rotateIn' : '']"
        alt=""
        @click="changeAddress"
      />
      <div
        ref="end"
        :class="[
          'address-item van-ellipsis text-right animate__animated',
          isChange ? 'animate__lightSpeedInLeft' : '',
        ]"
        @click="$emit('end')"
      >
        {{ end || "到达地" }}
      </div>
    </div>
    <div class="time" @click="$emit('date')">
      <div class="time-value">
        <span
          >{{ date }}<span class="week">{{ week }}</span></span
        >
      </div>
      <van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    start: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    week: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      isChange: false
    }
  },
  methods: {
    changeAddress() {
      let _this = this
      if (this.start && this.end) {
        _this.isChange = true
        setTimeout(function () {
          _this.isChange = false
        }, 1000)
        _this.$emit('changeAddress')
      } else {
        this.$toast('请先添加出发地或到达地')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.97rem;
  margin-top: 0.9rem;
  padding: 0 0.82rem;
  > img {
    width: 1.64rem;
    height: 1.64rem;
  }
  .address-item {
    cursor: pointer;
    display: flex;
    flex: 1;
    font-size: 0.86rem;
    font-weight: 500;
    color: #162b40;
  }
  > .text-left {
    justify-content: flex-start;
  }
  > .text-right {
    justify-content: flex-end;
  }
}
.time {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.74rem 0.82rem 0.55rem 0.82rem;
  // border-bottom: 0.02rem solid #ececec;
  > img {
    width: 0.98rem;
    height: 0.98rem;
  }
  .time-value {
    span {
      font-size: 0.86rem;
      font-weight: 500;
      color: #222625;
    }
    .week {
      font-size: 0.57rem;
      color: rgba(22, 43, 64, 0.65);
    }
  }
}
.time::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -6.555rem;
  width: 13.11rem;
  height: 0.02rem;
  background: #ececec;
}
</style>