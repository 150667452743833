<template>
    <div class="train-number-item" @click="$emit('handleClick', data)">
        <time-line :item="data"></time-line>
        <div class="seat-information">
            <p class="seat-label" v-for="(item, index) in data.seatList" :key="index">
                {{ item.seatName }}
                <span
                    v-if="item.canOrder == 1"
                    :style="{
                        color: item.num > 0 || item.num == '有' ? '#29d07c' : 'rgba(254, 166, 2, 1)'
                    }"
                    >{{ item.num == 0 ? '无票' : item.num }}</span
                >
                <span
                    v-else
                    :style="{
                        color: 'rgba(22, 43, 64, 0.15)'
                    }"
                    >{{ item.num == 0 ? '无票' : item.num }}</span
                >
            </p>
        </div>
    </div>
</template>

<script>
import TimeLine from './TimeLine.vue'
export default {
    props: {
        data: {}
    },
    components: {
        TimeLine
    }
}
</script>

<style lang="scss" scoped>
.train-number-item {
    width: 14.73rem;
    height: 4.59rem;
    margin: 0 auto;
    margin-top: 0.41rem;
    padding: 0.57rem 0.57rem 0.39rem 0.57rem;
    background: #ffffff;
    border-radius: 0.41rem;

    .seat-information {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        height: 1.48rem;
        // border-top: 1px solid #ececec;
        .seat-label {
            margin-right: 0.42rem;
            font-size: 0.49rem;
            color: rgba(22, 43, 64, 0.65);
            > span {
                font-size: 0.49rem;
            }
        }
    }
}
</style>