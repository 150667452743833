var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShowTabber)?_c('van-tabbar',{style:({
    width: _vm.contentWidth,
    zIndex: 2001,
    left: _vm.contentLeft,
    boxShadow: ' 0 0 10px 0 hsla(0,6%,58%,.3)',
  }),attrs:{"border":false},on:{"change":_vm.onChange},model:{value:(_vm.tabbarIndex),callback:function ($$v) {_vm.tabbarIndex=$$v},expression:"tabbarIndex"}},[_c('van-tabbar-item',{scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('div',{staticClass:"tabbar-item"},[_c('img',{attrs:{"src":props.active
              ? require('@assets/home_selected.png')
              : require('@assets/home.png')}}),_c('span',{staticClass:"title"},[_vm._v("接待")])])]}}],null,false,434104286)}),_c('van-tabbar-item',{scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('div',{staticClass:"tabbar-item"},[_c('img',{staticClass:"icon",attrs:{"src":props.active
              ? require('@assets/my_selected.png')
              : require('@assets/my.png')}}),_c('span',{staticClass:"title"},[_vm._v("我的")])])]}}],null,false,2984235936)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }