<template>
  <van-popup
    :style="{ width: contentWidth, left: contentLeft }"
    v-model="showPicker"
    position="bottom"
    class="my-picker"
    @close="$emit('close')"
    style="border-radius: 0.7rem 0.7rem 0 0"
  >
    <div class="main">
      <div class="main-header">
        <div class="header">
          <span>选择{{ isSetOut == 1 ? "出发地" : "到达地" }}</span>
          <img src="@assets/close.png" @click="$emit('close')" alt="" />
        </div>
        <!-- 搜索 -->
        <form action="/">
          <van-search
            v-model="searchText"
            show-action
            placeholder="输入城市、车站名称"
            @input="onSearch"
            @cancel="onCancel"
          />
        </form>
      </div>
      <!-- 索引栏 -->
      <div class="scroll" v-if="!isSearch">
        <van-index-bar :index-list="Object.keys(address)">
          <template v-for="(item, index) in Object.keys(address)">
            <van-index-anchor :index="item" :key="index" />
            <div v-if="transportation == 1">
              <div
                class="train-address-item"
                @click="selectItem(citem)"
                v-for="(citem, cindex) in address[item]"
                :key="cindex"
              >
                <span :class="[isSelect == citem.id ? 'active' : '']">{{
                  citem.stationName
                }}</span>
                <img
                  v-if="isSelect == citem.id"
                  src="@assets/selected.png"
                  alt=""
                />
              </div>
            </div>
            <div class="aircraft-warp" v-else>
              <div
                class="aircraft-address-item"
                @click="selectItem(citem)"
                v-for="(citem, cindex) in address[item]"
                :key="cindex"
              >
                <span :class="[isSelect == citem.id ? 'active' : '']">{{
                  citem.stationName
                }}</span>
                <!-- <img
                  v-if="isSelect == citem.id"
                  src="@assets/selected.png"
                  alt=""
                /> -->
              </div>
            </div>
          </template>
        </van-index-bar>
      </div>
      <!-- 搜索样式 -->
      <div class="scroll" v-else>
        <!-- 火车  搜索站点-->
        <template v-if="transportation == 1">
          <div
            class="search-list-item"
            v-for="(item, index) in Object.keys(list)"
            :key="index"
            @click="selectItem(item)"
          >
            <!-- Object.keys(list) -->
            <div class="search-label" @click="clickCityName(item)">
              <span class="city"> 城市</span>
              <span
                v-for="(aitem, aindex) in item"
                :key="aindex"
                :style="{
                  color: [...searchText].includes(aitem) ? '#0072f5' : '',
                }"
                >{{ aitem }}</span
              >
            </div>
            <div
              class="search-value"
              v-for="(bitem, bindex) in list[item]"
              :key="bindex"
              @click="selectItem(bitem)"
            >
              <div>
                <span class="station">{{
                  transportation == 1 ? "火车站" : "机场"
                }}</span>
                <span
                  v-for="(citem, cindex) in bitem.stationName"
                  :key="cindex"
                  :style="{
                    color: [...searchText].includes(citem) ? '#0072f5' : '',
                  }"
                  >{{ citem }}</span
                >
              </div>
            </div>
          </div>
        </template>
        <!-- 飞机 指搜索城市 -->
        <template v-else>
          <div
            class="search-list-item"
            v-for="(item, index) in list"
            :key="index"
            @click="selectItem(item)"
          >
            <!-- Object.keys(list) -->
            <div class="search-label">
              <!-- <span class="city"> 城市</span> -->
              <span
                v-for="(aitem, aindex) in item.cityName"
                :key="aindex"
                :style="{
                  color: [...searchText].includes(aitem) ? '#0072f5' : '',
                }"
                >{{ aitem }}</span
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </van-popup>
</template>

<script>
import address from '../../constant/address'
import { mapGetters } from 'vuex'
export default {
  props: {
    showPicker: {
      type: Boolean,
      default: false
    },
    // 1 火车 2 飞机
    transportation: {
      type: Number,
      default: ''
    },
    // 1 出发地 2 目的地
    isSetOut: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      address: {},
      searchText: '',
      isSelect: '',
      list: {},
      isSearch: false
    }
  },
  computed: {
    ...mapGetters(['contentWidth', 'contentLeft'])
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$Req('common/getDictStationList', {
        state: 1,
        stationType: this.transportation,
        type: 2
      }).then(res => {
        let obj = {}
        let _this = this
        _this.$nextTick(() => {
          res.map(item => {
            if (item[Object.keys(item)[0]].length > 0)
              obj[Object.keys(item)[0]] = item[Object.keys(item)[0]]
          })
          _this.address = obj
        })
      })
    },
    onSearch() {
      if (this.searchText)
        this.isSearch = true
      else this.isSearch = false
      this.$Req('common/getDictStationList', {
        state: 1,
        stationName: this.searchText,
        stationType: this.transportation,
        version: this.transportation == 1 ? '' : 'v2'
      }).then(res => {
        let obj = {}
        if (this.transportation == 1) {
          res.map(item => {
            if (item[Object.keys(item)[0]].length)
              obj[Object.keys(item)[0]] = item[Object.keys(item)[0]]
          })
          this.list = obj
        } else {
          this.list = res[0]['城市']
        }


      })
    },
    onCancel() {
      this.isSearch = false
    },
    selectItem(item) {
      this.isSelect = item.id
      item = { ...item, isSetOut: this.isSetOut }
      this.isSearch = false
      this.searchText = ''
      this.$emit('close')
      this.$emit('comfire', item)

    },
    clickCityName(name) {
      let item = {
        stationName: name,
        cityName: name
      }
      item = {
        ...item, isSetOut: this.isSetOut
      }
      this.isSearch = false
      this.searchText = ''
      this.$emit('close')
      this.$emit('comfire', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 0.53rem 0;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .main-header {
    position: relative;
    z-index: 11;
    background: #fff;
  }
  .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.66rem;

    span {
      font-size: 0.66rem;
      font-weight: 500;
      color: #323233;
    }
    > img {
      width: 0.9rem;
      height: 0.9rem;
    }
  }
  .scroll {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .train-address-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 1.81rem;
    border-bottom: 1px solid #ececec;
    padding: 0 1.46rem 0 0.66rem;
    > span {
      font-size: 0.57rem;
      color: #323233;
    }
    .active {
      color: #0072f5;
    }
    > img {
      width: 0.66rem;
      height: 0.66rem;
    }
  }
  .aircraft-warp {
    display: flex;
    padding: 0.41rem 0.66rem;
    flex-wrap: wrap;
    &:nth-child(2n + 1) {
      margin-right: 0;
    }
  }
  .aircraft-address-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 4.47rem;
    height: 1.39rem;
    text-align: center;
    line-height: 1.39rem;
    border-radius: 0.08rem;
    margin-bottom: 0.25rem;
    margin-right: 0.33rem;
    border: 0.02rem solid #ececec;
  }
  .search-list-item {
    cursor: pointer;
    padding: 0 0.66rem;
    .search-label {
      display: flex;
      align-items: center;
      padding: 0.42rem 0;
      border-bottom: 0.04rem solid #ececec;
      > span {
        font-size: 0.57rem;
        font-weight: 500;
      }
    }
    .city {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.8rem;
      padding: 0 0.21rem;
      margin-right: 0.21rem;
      background: #0072f5;
      font-size: 0.49rem !important;

      color: #fff;
    }
    .search-value {
      padding-left: 2rem;
      > div {
        display: flex;
        align-items: center;
        padding: 0.42rem 0;
        border-bottom: 0.04rem solid #ececec;
      }
      .station {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        padding: 0 0.21rem;
        margin-right: 0.21rem;
        border: 0.04rem solid #ececec;
        font-size: 0.49rem !important;
        color: #0072f5;
      }
    }
  }
  ::v-deep {
    .van-search {
      padding: 0 0.66rem;
      margin: 0.37rem 0;
      .van-search__content {
        background: #f7f8fa;
        border-radius: 0.33rem;
      }
    }
    .van-index-anchor {
      padding: 0 0.66rem;
      width: 100%;
      height: 1.31rem;
      background: #f7f8fa;
    }
    .van-index-bar__sidebar {
      position: absolute;
      top: 50%;
      .van-index-bar__index {
        font-size: 0.49rem;
        color: #646566;
      }
      .van-index-anchor--sticky {
        position: fixed !important;
      }
      .van-index-bar__index--active {
        color: #ee0a24;
      }
    }
  }
}
</style>