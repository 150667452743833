var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details"},[_c('div',{staticClass:"details-header"},[_c('Navbar',{attrs:{"title":_vm.title,"bgColor":'transparent'}})],1),_c('div',{staticClass:"chunk"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(this.date)+" "+_vm._s(_vm.routerData.week))]),_c('time-line',{attrs:{"item":_vm.routerData}}),(_vm.transportation == 2)?_c('div',{staticClass:"aircraft-tips"},[_c('span',[_vm._v(_vm._s(_vm.routerData.food == 1 ? "有餐食" : "无餐食"))]),(_vm.routerData.craft)?_c('span',[_vm._v(_vm._s(_vm._f("craftName")(_vm.routerData.craft)))]):_vm._e(),(_vm.routerData.punctualRate)?_c('span',[_vm._v(_vm._s(_vm.routerData.punctualRate)+"%准点率")]):_vm._e()]):_vm._e(),(_vm.transportation == 1)?_c('div',{staticClass:"seat-list"},_vm._l((_vm.seatList),function(item,index){return _c('div',{key:index,class:['seat-item', _vm.seatType === index ? 'active' : ''],style:({ background: !item.canOrder ? '#F3F5F7' : '' }),on:{"click":function($event){return _vm.handleSeat(item, index)}}},[(!item.canOrder || item.num == 0 || item.num == '无')?[_c('span',{style:({
              color:
                !item.canOrder || item.num == 0 || item.num == '无'
                  ? 'rgba(22, 43, 64, 0.15)'
                  : '',
            })},[_vm._v(_vm._s(item.seatName))]),_c('span',{staticStyle:{"color":"rgba(22, 43, 64, 0.15)"}},[_vm._v(_vm._s(item.num)+_vm._s(item.num === "有" || item.num === "无" ? "" : "张"))])]:[_c('span',{style:({
              color:
                !item.canOrder || item.num == 0
                  ? 'rgba(22, 43, 64, 0.15)'
                  : '',
            })},[_vm._v(_vm._s(item.seatName))]),_c('span',{style:({ color: item.num === 0 ? 'rgba(22, 43, 64, 0.15)' : '' })},[_vm._v(_vm._s(item.num)+_vm._s(item.num === "有" || item.num === "无" ? "" : "张"))])],(_vm.seatType == index)?_c('img',{attrs:{"src":require("@assets/selected.png"),"alt":""}}):_vm._e()],2)}),0):_c('div',{staticClass:"seat-list"},_vm._l((_vm.seatList),function(item,index){return _c('div',{key:index,class:['seat-item', _vm.seatType === index ? 'active' : ''],style:({ background: !item.canOrder ? '#F3F5F7' : '' }),on:{"click":function($event){return _vm.handleSeat(item, index)}}},[(!item.canOrder)?[_c('span',{style:({
              color:
                !item.canOrder || item.num == 0 || item.num == '无'
                  ? 'rgba(22, 43, 64, 0.15)'
                  : '',
            })},[_vm._v(_vm._s(item.seatName))])]:[_c('span',{style:({
              color: !item.canOrder ? 'rgba(22, 43, 64, 0.15)' : '',
            })},[_vm._v(_vm._s(item.seatName))])],(_vm.seatType == index)?_c('img',{attrs:{"src":require("@assets/selected.png"),"alt":""}}):_vm._e()],2)}),0)],1),_c('div',{staticClass:"chunk",staticStyle:{"margin-top":"0.41rem"}},[_c('div',{staticClass:"title",staticStyle:{"text-align":"left","font-weight":"500"}},[_vm._v(" 订票人信息 ")]),_c('div',{staticClass:"line"}),_c('van-field',{attrs:{"readonly":"","label":"姓名","placeholder":"须于证件姓名一致","input-align":"right"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{staticClass:"line"}),_c('van-cell',{attrs:{"title":"证件类型","value":_vm.utils.getDictionaryText(_vm.cardType, _vm.columns),"is-link":""},on:{"click":function($event){_vm.showPicker = true}}}),_c('div',{staticClass:"line"}),_c('van-field',{attrs:{"label":"证件号码","placeholder":"请输入证件号码","input-align":"right"},model:{value:(_vm.cardNo),callback:function ($$v) {_vm.cardNo=$$v},expression:"cardNo"}})],1),_c('div',{staticClass:"submit",on:{"click":_vm.submit}},[_c('span',[_vm._v(_vm._s(_vm.routerData.ticketId ? "申请改签" : "提交订单"))])]),_c('van-popup',{staticClass:"my-picker",staticStyle:{"border-radius":"0.7rem 0.7rem 0 0"},style:({ width: _vm.contentWidth, left: _vm.contentLeft }),attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.columns},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showPicker = false}}})],1),_c('OperationDialog',{attrs:{"show":_vm.isOperation,"type":_vm.routerData.ticketId ? 2 : 1},on:{"handleOperation":_vm.handleOperation,"close":function($event){_vm.isOperation = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }