<template>
  <div class="no-data">
    <img :src="icon" alt="" />
    <span class="title">{{ title }}</span>
    <span v-if="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: require('@assets/no_arrange.png')
    },
    algin: {
      type: String,
      default: ''
    }
  }

}
</script>

<style lang="scss" scoped>
.no-data {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.57rem 0.92rem;
  > img {
    width: 4.92rem;
    height: 4.92rem;
  }

  > span {
    text-align: center;
    font-size: 0.57rem;
    color: rgba(22, 43, 64, 0.65);
  }
  .title {
    font-size: 0.57rem;
    font-weight: 500;
    color: #162b40;
  }
}
</style>