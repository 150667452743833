<template>
  <div class="my">
    <div class="my-header">
      <div class="personal-information">
        <div class="head-sculpture">
          <img
            :src="userInfo.profilePic || require('@assets/default_photo.png')"
            alt=""
          />
        </div>
        <div class="job-details">
          <div class="name">
            <span>{{ userInfo.name }}</span>
            <span>{{ userInfo.job }}</span>
          </div>
          <div class="work-address">
            <span>{{ userInfo.company }}</span>
          </div>
        </div>
      </div>
      <!-- <Navbar :title="title" :bgColor="'transparent'"></Navbar> -->
    </div>
    <div class="content">
      <div class="work-list">
        <van-cell title="个人信息" @click="personalInformation" is-link />
        <van-cell title="订票记录" @click="bookingRecord" is-link />
      </div>
      <div class="login-out" @click="loginOut">
        <span>退出登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@components/Common/Navbar'
import { mapState } from 'vuex'
export default {
  components: {
    Navbar
  },
  data() {
    return {
      title: '我的'
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'guestId'])
  },
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push('/login')
      return
    }
    this.$store.commit('setTabbarIndex', 1)
    this.getData()
  },
  methods: {
    getData() {
      this.$Req(`common/guests`, { guestId: this.guestId }).then(res => {
        this.data = res
        sessionStorage.setItem('name', res.name)
        this.$store.commit('user/setUserInfo', {
          phone: res.phone,
          name: res.name,
          cardNo: res.cardNo,
          area: res.area,
          company: res.company,
          cardType: res.cardType,
          department: res.department,
          job: res.job,
          sex: res.sex,
          cardNo: res.cardNo,
          appendState: res.appendState,
          profilePic: res.profilePic
        })
        this.$hideLoading()
      }).catch(err => {
        this.$hideLoading()
        console.log(err)
      })
    },
    personalInformation() {
      this.$router.push({
        path: '/personalInformation'
      })
    },
    bookingRecord() {
      this.$router.push({
        path: '/bookingRecord'
      })
    },
    loginOut() {
      this.$dialog.confirm({
        message: '您确认要退出登录么?',
        confirmButtonText: '取消',
        cancelButtonText: '退出登录',
        cancelButtonColor: '#323233',
        confirmButtonColor: '#0089FF'
      }).then(() => {
        console.log('点击取消')
      }).catch(() => {
        this.$store.dispatch('user/logout')
        this.$router.push({
          path: '/login'
        })
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.my {
  display: flex;
  flex-direction: column;
  .my-header {
    width: 100%;
    height: 10.56rem;
    padding: 0 0.33rem;
    background: url(~@assets/my_bg.png) no-repeat center/cover;
    .personal-information {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 4.51rem;
      margin-top: 4.51rem;
      background: #ffffff;
      border-radius: 0.49rem;
      padding: 0 1.03rem;
      .head-sculpture {
        width: 2.46rem;
        height: 2.46rem;
        border: 0.02rem solid #ffffff;
        border-radius: 100%;
        > img {
          width: 2.46rem;
          height: 2.46rem;
          border-radius: 100%;
        }
      }
      .job-details {
        flex: 1;
        display: flex;
        // align-items: center;
        flex-direction: column;
        margin-left: 0.57rem;
        .name {
          span:nth-child(1) {
            font-size: 0.74rem;
            font-weight: 500;
            color: #162b40;
          }
          span:nth-child(2) {
            margin-left: 0.41rem;
            font-size: 0.49rem;
            color: rgba(22, 43, 64, 0.65);
          }
        }
        .work-address {
          margin-top: 0.33rem;
          span {
            font-size: 0.49rem;
            color: rgba(22, 43, 64, 0.65);
          }
        }
      }
    }
  }
  .content {
    padding: 0 0.33rem;
  }
  .work-list {
    margin-top: -1rem;
    padding: 0 0.33rem;
    // height: 7.38rem;
    background: #ffffff;
    border-radius: 0.49rem;
    background: #fff;
  }
  .work-list::v-deep {
    .van-cell__title {
      font-size: 0.66rem;
    }
    .van-cell {
      padding: 14px 16px;
    }
  }
  .login-out {
    cursor: pointer;
    width: 100%;
    height: 1.64rem;
    line-height: 1.64rem;
    text-align: center;
    margin-top: 0.82rem;
    border-radius: 0.25rem;
    border: 0.02rem solid #dadada;
    > span {
      font-size: 0.57rem;
      color: rgba(22, 43, 64, 0.45);
    }
  }
}
</style>