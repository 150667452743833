// import Vue from 'vue'
// import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\index.js$/)
export default new Vuex.Store({
  modules: modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/index.js|\/|\./g, '')
    modules[moduleName] = modulesFiles(modulePath).default
    return modules
  }, {}),
  getters
})
