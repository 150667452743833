export default {
    setToken(state, token) {
        localStorage.setItem('token', token)
        state.token = token
    },
    setGuestId(state, id) {
        localStorage.setItem('guestId', id)
        state.guestId = id
    },
    setUserInfo(state, info) {
        localStorage.setItem('userInfo', JSON.stringify(info))
        state.userInfo = info
    },

};