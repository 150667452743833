<template>
  <div
    class="list-item"
    @click="$emit('handleClick', { item: item, index: index })"
    :style="{ border: isSelected ? '0.04rem solid #0072F5' : 'none' }"
  >
    <div class="item-time">
      <div class="start">
        <span class="time">{{ item.startTime }}</span>
        <span class="address"
          >{{ item.startStation }}{{ item.startTerminal }}</span
        >
      </div>
      <div class="center">
        <span>{{ item.costTime }}</span>
        <img src="@assets/route_line.png" alt="" />
      </div>
      <div class="end">
        <span class="time">{{ item.endTime }}</span>
        <span class="address">{{ item.endStation }}{{ item.endTerminal }}</span>
        <span class="tips" v-if="item.day >= 1">+{{ item.day }}天</span>
      </div>
    </div>
    <div
      class="item-select"
      :style="{
        background: isSelected
          ? 'linear-gradient(270deg, rgba(0,119,255,0.45) 0%, rgba(244,244,244,0) 80% , rgba(255,255,255,.5) 90%, rgba(244,244,244,0.65) 100%'
          : '',
      }"
    >
      <span v-if="item.ticketNo">{{ item.ticketNo }}</span>
      <template v-else>
        <span>{{ type == 2 ? item.flightNo : item.trainNo }}</span>
        <span class="ariline">{{ item.airline }}</span>
      </template>
      <img src="@assets/selected.png" v-if="isSelected" alt="" />
      <span class="cricle" v-else></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    item: {},
    type: {},
    index: {}
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  width: 14.73rem;
  height: 3.04rem;
  margin: 0 auto;
  margin-top: 0.41rem;

  border-radius: 0.41rem;
  .item-time {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0.66rem;
    .start,
    .end {
      width: 3.8rem;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .time {
        font-size: 0.98rem;
        font-weight: 600;
        color: #162b40;
      }
      .address {
        margin-top: 0.28rem;
        font-size: 0.49rem;
        color: rgba(22, 43, 64, 0.85);
      }
      .tips {
        position: absolute;
        top: -0.5rem;
        right: 0;
        font-size: 0.45rem;
        color: rgba(22, 43, 64, 0.85);
      }
    }
    .start {
      align-items: flex-start;
    }
    .end {
      align-items: flex-end;
    }
    .center {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > img {
        width: 3.08rem;
        height: 0.15rem;
        margin: 0.1rem 0;
      }
      > span {
        font-size: 0.49rem;
        color: #162b40;
      }
    }
  }
  .item-select {
    width: 4.28rem;
    height: 3rem;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(244, 244, 244, 0.65) 100%
    );
    border-radius: 0rem 0.41rem 0.41rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > span {
      margin-bottom: 0.21rem;
      font-size: 0.66rem;
      font-weight: 500;
      color: #162b40;
    }
    .cricle {
      width: 0.66rem;
      height: 0.66rem;
      background: #dcdee0;
      border-radius: 100%;
    }
    > img {
      width: 0.66rem;
      height: 0.66rem;
    }
    .ariline {
      font-size: 0.49rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(22, 43, 64, 0.65);
    }
  }
}
</style>