/*
* type: 类型 'hour', 'day'
* startTime: 开始时间 例：'2020-09-22 00:00:00'
* endTime: 结束时间   例：'2020-09-23 23:00:00'
*/
// 得到中间的时间
function getMiddleData(type, startTime, endTime) {
    const dealTimeArr = [];
    let dealStartTime = new Date(startTime).getTime();
    const dealEndTime = new Date(endTime).getTime();
    if (type === 'hour') {
        while (true) {
            if (dealStartTime <= dealEndTime) {
                const itemTime = isChinaStandardTime((new Date(dealStartTime)).toString(), 'Y-m-d h:00:00');
                dealTimeArr.push(itemTime);
                dealStartTime += 1 * 60 * 60 * 1000;
            } else {
                break;
            }
        }
    } else if (type === 'day') {
        while (true) {
            if (dealStartTime <= dealEndTime) {
                const itemTime = isChinaStandardTime((new Date(dealStartTime)).toString(), 'Y-m-d');
                dealTimeArr.push(itemTime);
                // debugger;
                dealStartTime += 24 * 60 * 60 * 1000;
            } else {
                break;
            }
        }
    }
    return dealTimeArr
}
function isChinaStandardTime(time, format) {
    let temp = '';
    // 判断 时间 是否是 时间字符串， 还是中国标准时间，是中国标准时间 就转换
    if (time.indexOf('中国标准时间') !== -1) {
        temp = dealFormData(time, format);
        return temp;
    }
    return time;
}
function dealFormData(timeStamp, fmt = 'Y-m-d h:00:00') {
    const date = new Date(timeStamp);
    const tmpYear = date.getFullYear();
    const tmpMon = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    const tmpDay = (date.getDate() > 9) ? date.getDate() : '0' + (date.getDate());
    const tmpHour = (date.getHours()) > 9 ? date.getHours() : '0' + (date.getHours());
    const tmpMinutes = (date.getMinutes()) > 9 ? date.getMinutes() : '0' + (date.getMinutes());
    const tmpSeconds = (date.getSeconds()) > 9 ? date.getSeconds() : '0' + (date.getSeconds());
    let finalData = '';
    switch (fmt) {
        case 'Y-m-d h:m:s':
            finalData = tmpYear + '-' + tmpMon + '-' + tmpDay + ' ' + tmpHour + ':' + tmpMinutes + ':' + tmpSeconds;
            break;
        case 'Y-m-d h:00:00':
            finalData = tmpYear + '-' + tmpMon + '-' + tmpDay + ' ' + tmpHour + ':00:00';
            break;
        case 'Y-m-d':
            finalData = tmpYear + '-' + tmpMon + '-' + tmpDay;
            break;
        default:
        //
    }
    return finalData;
}
// 获取周
function getWeek(date) {
    let weeks = ['日', '一', '二', '三', '四', '五', '六']
    let day = new Date(date || new Date()).getDay()
    return weeks[day]
}
// 获取字典渲染
function getDictionaryText(code, ary) {
    if (code === '') return
    let name = ''
    ary.map(item => {
        if (item.code == code) name = item.codeDesc
    })
    return name
}
// 修改接送站字典数据
function dealDictText(text, type = 1) {
    let dictText = ''
    switch (text) {
        case '待安排':
            dictText = '安排中'
            break
        case '他人已送达':
            dictText = type == 1 ? '已接站' : '已送站'
            break
        case '司机已确定':
            dictText = type == 1 ? '待接站' : '待送站'
            break
        case '已送达':
            dictText = type == 1 ? '已接站' : '已送站'
            break
        default:
            dictText = text
    }
    return dictText
}
export default {
    getMiddleData,
    getWeek,
    getDictionaryText,
    dealFormData,
    dealDictText
}
