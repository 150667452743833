<template>
  <div class="record-tab">
    <div
      :class="['tab-item ', activeIndex == 1 ? 'active' : '']"
      @click="$emit('click', 1)"
    >
      来程订票
    </div>
    <div
      :class="['tab-item ', activeIndex == 2 ? 'active' : '']"
      @click="$emit('click', 2)"
    >
      返程订票
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.record-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.85rem;
  background: #ffffff;
  border-radius: 0.49rem;
  padding: 0 0.16rem;
  .tab-item {
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.66rem;
    color: #162b40;
  }
  .active {
    height: 1.56rem;
    background: rgba(0, 114, 245, 0.1);
    border-radius: 0.41rem;
    font-size: 0.66rem;
    font-weight: 500;
    color: #0072f5;
  }
}
</style>