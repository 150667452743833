import OSS from 'ali-oss'

class GATOSSFile {
    constructor(file) {
        this.file = file
    }

    checkExt(acceptStr) {
        if (acceptStr === '*') return true
        let result = false
        const fileExt = this.getFileExt()
        for (let ext of acceptStr.split(',')) {
            if (ext === fileExt) {
                result = true
                break
            }
        }

        return result
    }

    checkSize(sizeStr) {
        if (sizeStr === '*') return true
        const fileSize = this.file.size
        const sizeUnit = sizeStr.match(/[A-Z]+$/)[0]
        const size = parseInt(sizeStr.replace(sizeUnit, ''))

        const pow = ['B', 'K', 'M', 'G', 'T'].findIndex(unit => unit === sizeUnit)
        return fileSize <= Math.pow(1024, pow <= 1 ? 1 : pow) * size
    }

    getCheckpoint() {
        return JSON.parse(sessionStorage.getItem(`${this.file.name}_${this.file.size}`))
    }

    saveCheckpoint(checkpoint) {
        sessionStorage.setItem(`${this.file.name}_${this.file.size}`, JSON.stringify(checkpoint))
    }

    removeCheckpoint() {
        return sessionStorage.removeItem(`${this.file.name}_${this.file.size}`)
    }

    getFileExt() {
        return this.file.name.match(/\.(\w+)$/)[1]
    }

    getUploadPath() {
        return `upload_tmp/${this._getDate()}/${this.file.name.replace(/ /g, '')}`
    }

    _getDate() {
        return new Date()
            .toLocaleDateString()
            .split('/')
            .map(date => (date.length < 2 ? (date = '0' + date) : date))
            .join('')
    }
}

export default class GATOSSUploader {

    // config: OSSConfig,
    // maxSize: 200M,
    // accept: mov,mp4
    constructor(config) {
        this._uploader = new OSS(config)
    }

    uploadFiles(files, callback) {
        const fileProgress = new Array(files.length).fill(0)
        return Promise.all(Array.prototype.map.call(files, (file, index) => {
            return new Promise((resolve, reject) => {
                this.upload(file, progress => {
                    fileProgress[index] = progress
                    callback(fileProgress)
                }).then(res => {
                    resolve({
                        index,
                        res,
                    })
                }).catch(e => reject(e)) // 还可以优化 promise.all
            })
        }))
    }

    upload({
        file,
        maxSize,
        accept
    }, callback) {
        const ossFile = new GATOSSFile(file)

        if (!ossFile.checkExt(accept)) return Promise.reject(new Error(`不支持的文件格式,支持列表:${accept}`))
        else if (!ossFile.checkSize(maxSize)) return Promise.reject(new Error(`类型的文件不得超过${maxSize}`))

        return new Promise((resolve, reject) => {
            this._uploader.multipartUpload(ossFile.getUploadPath(), ossFile.file, {
                checkpoint: ossFile.getCheckpoint(),
                progress(p, checkpoint) {
                    if (checkpoint) ossFile.saveCheckpoint(checkpoint)
                    if (p === 1) ossFile.removeCheckpoint()
                    callback && callback(parseInt(p * 100))
                }
            }).then(res => {
                resolve(res.name)
            }).catch(e => reject(e))
        })
    }
}