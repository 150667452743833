<template>
  <div class="details">
    <div class="details-header">
      <Navbar :title="title" :bgColor="'transparent'"></Navbar>
    </div>
    <div class="chunk">
      <div class="title">{{ this.date }} {{ routerData.week }}</div>
      <time-line :item="routerData"></time-line>
      <div class="aircraft-tips" v-if="transportation == 2">
        <span>{{ routerData.food == 1 ? "有餐食" : "无餐食" }}</span>
        <span v-if="routerData.craft">{{ routerData.craft | craftName }}</span>
        <span v-if="routerData.punctualRate"
          >{{ routerData.punctualRate }}%准点率</span
        >
      </div>
      <div class="seat-list" v-if="transportation == 1">
        <div
          v-for="(item, index) in seatList"
          :key="index"
          @click="handleSeat(item, index)"
          :class="['seat-item', seatType === index ? 'active' : '']"
          :style="{ background: !item.canOrder ? '#F3F5F7' : '' }"
        >
          <template v-if="!item.canOrder || item.num == 0 || item.num == '无'">
            <span
              :style="{
                color:
                  !item.canOrder || item.num == 0 || item.num == '无'
                    ? 'rgba(22, 43, 64, 0.15)'
                    : '',
              }"
              >{{ item.seatName }}</span
            >
            <span style="color: rgba(22, 43, 64, 0.15)"
              >{{ item.num
              }}{{ item.num === "有" || item.num === "无" ? "" : "张" }}</span
            >
          </template>
          <template v-else>
            <span
              :style="{
                color:
                  !item.canOrder || item.num == 0
                    ? 'rgba(22, 43, 64, 0.15)'
                    : '',
              }"
              >{{ item.seatName }}</span
            >
            <span
              :style="{ color: item.num === 0 ? 'rgba(22, 43, 64, 0.15)' : '' }"
              >{{ item.num
              }}{{ item.num === "有" || item.num === "无" ? "" : "张" }}</span
            >
          </template>
          <img src="@assets/selected.png" v-if="seatType == index" alt="" />
        </div>
      </div>
      <div class="seat-list" v-else>
        <div
          v-for="(item, index) in seatList"
          :key="index"
          @click="handleSeat(item, index)"
          :class="['seat-item', seatType === index ? 'active' : '']"
          :style="{ background: !item.canOrder ? '#F3F5F7' : '' }"
        >
          <template v-if="!item.canOrder">
            <span
              :style="{
                color:
                  !item.canOrder || item.num == 0 || item.num == '无'
                    ? 'rgba(22, 43, 64, 0.15)'
                    : '',
              }"
              >{{ item.seatName }}</span
            >
          </template>
          <template v-else>
            <span
              :style="{
                color: !item.canOrder ? 'rgba(22, 43, 64, 0.15)' : '',
              }"
              >{{ item.seatName }}</span
            >
          </template>
          <img src="@assets/selected.png" v-if="seatType == index" alt="" />
        </div>
      </div>
    </div>
    <div class="chunk" style="margin-top: 0.41rem">
      <div class="title" style="text-align: left; font-weight: 500">
        订票人信息
      </div>
      <div class="line"></div>
      <van-field
        v-model="name"
        readonly
        label="姓名"
        placeholder="须于证件姓名一致"
        input-align="right"
      />
      <div class="line"></div>
      <van-cell
        title="证件类型"
        :value="utils.getDictionaryText(cardType, columns)"
        is-link
        @click="showPicker = true"
      />
      <div class="line"></div>
      <van-field
        v-model="cardNo"
        label="证件号码"
        placeholder="请输入证件号码"
        input-align="right"
      />
    </div>
    <div class="submit" @click="submit">
      <span>{{ routerData.ticketId ? "申请改签" : "提交订单" }}</span>
    </div>
    <van-popup
      v-model="showPicker"
      position="bottom"
      class="my-picker"
      style="border-radius: 0.7rem 0.7rem 0 0"
      :style="{ width: contentWidth, left: contentLeft }"
    >
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- 获取操作人信息 -->
    <OperationDialog
      :show="isOperation"
      @handleOperation="handleOperation"
      @close="isOperation = false"
      :type="routerData.ticketId ? 2 : 1"
    ></OperationDialog>
  </div>
</template>

<script>
import OperationDialog from '@components/Common/OperationDialog.vue'
import Navbar from '@components/Common/Navbar'
import TimeLine from './components/TimeLine.vue'
import { mapGetters, mapState } from 'vuex'
import utils from '@utils/index.js'
export default {
  components: {
    TimeLine,
    Navbar,
    OperationDialog
  },
  data() {
    return {
      utils,
      title: '确认订票',
      name: '',
      cardType: '身份证',
      cardNo: '',
      seatType: -1,
      seat: '',
      showPicker: false,
      columns: [],
      week: '',
      date: '',
      transportation: '',
      routerData: {},
      seatList: [],
      isOperation: false,
    }
  },
  filters: {
    craftName(val) {
      if (!val) return
      if (val[0] == '3') {
        return `空客${val}`
      } else if (val[0] == 7) {
        return `波音${val}`
      } else {
        return val
      }
    }
  },
  mounted() {
    this.routerData = this.$route.query
    this.title = this.routerData.ticketId ? "申请改签" : '确认订票'
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    console.log(this.userInfo, 'this.userInfo')
    this.name = sessionStorage.getItem('name')
    this.cardType = this.userInfo.cardType
    this.cardNo = this.userInfo.cardNo

    const { week, transportation } = this.routerData
    this.transportation = transportation
    this.name = sessionStorage.getItem('name')
    this.week = week
    this.date = utils.dealFormData(sessionStorage.getItem('currentDate'), 'Y-m-d')
    this.seatList = JSON.parse(sessionStorage.getItem('seatList'))
    this.getDict()
    this.$hideLoading()
  },
  computed: {
    ...mapGetters(['contentWidth', 'contentLeft']),
    ...mapState('user', ['guestId'])
  },
  methods: {
    getDict() {
      this.$Req('common/getDicts', { dictName: "card_type" }).then(data => {
        this.columns = data
        this.columns.map(item => {
          item.text = item.codeDesc
          item.value = item.code
        })
      })
    },
    // 选择座位
    handleSeat(item, index) {
      if (item.canOrder === 0) return
      if (this.transportation == 2) {
        this.seatType = index
        this.seat = item.seat
      } else {
        if (item.num != 0 && item.num != '无') {
          this.seatType = index
          this.seat = item.seat
        }
      }
    },
    handleOperation(data) {
      const { punctualRate, endTerminal, craft, food, startTerminal, airline, flightNo, trainNo, startTime, endTime, startStation, endStation, startCity, endCity, type, day, bookingMethod, ticketStatus, ticketId } = this.routerData
      let params = {
        guestId: this.guestId,
        ticketNo: trainNo || flightNo,
        dateTime: this.date,
        startTime: startTime,
        endTime: endTime,
        startStation: startStation,
        endStation: endStation,
        seat: this.seat,
        startTerminal: startTerminal,
        airline: airline,
        craft: craft,
        food: food,
        endTerminal: endTerminal,
        startCity: startCity,
        punctualRate: punctualRate,
        endCity: endCity,
        cardType: this.cardType,
        cardNo: this.cardNo,
        trafficType: this.transportation,
        type: type,
        day: day,
        bookingMethod: bookingMethod, //是否是个人添加
        ticketId: ticketId, //订票id 修改时传
        ticketStatus: ticketStatus, //10-在线预定 20-确认出票 25-个人添加 30-申请改签 40-确认改签
        ...data
      }
      this.$Req('common/submitTickets', params).then(res => {
        this.$toast(ticketId ? '已申请' : '订单已提交')
        this.$router.push({
          path: '/'
        })
        console.log(res)
      })
    },
    // 提交订单
    submit() {
      if (!this.seat) {
        this.$toast('请选择座位！')
        return
      }
      if (!this.cardType) {
        this.$toast('请选择证件类型')
        return
      }
      if (!this.cardNo) {
        this.$toast('请输入证件号')
        return
      }
      this.isOperation = true
    },
    // 选择身份
    onConfirm(item) {
      this.cardType = item.code
      this.showPicker = false
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f3f5f7;
  .details-header {
    width: 100%;
    height: 6.56rem;
    background: linear-gradient(180deg, #e1ecff, #f2f5f7);
  }
  .chunk {
    width: 14.73rem;
    margin: 0 auto;
    padding: 0.82rem 0.57rem 0 0.57rem;
    margin-top: -4.4rem;
    background: #ffffff;
    border-radius: 0.49rem;
    .title {
      margin-bottom: 0.62rem;
      font-size: 0.66rem;
      color: #162b40;
      text-align: center;
    }
    .seat-list {
      display: flex;
      align-items: center;
      //   justify-content: space-between;
      padding: 0.82rem 0;
      border-top: 0.02rem solid #ececec;
      .seat-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 2.46rem;
        background: #f3f5f7;
        border-radius: 0.33rem;
        margin-right: 0.16rem;
        span:nth-child(1) {
          font-size: 0.66rem;
          font-weight: 500;
          color: rgba(22, 43, 64, 0.85);
        }
        span:nth-child(2) {
          font-size: 0.49rem;
          color: rgba(22, 43, 64, 0.65);
        }
      }
      &:last-child {
        margin-right: 0rem;
      }
      .active {
        position: relative;
        background: rgba(0, 114, 245, 0.15);
        border: 0.02rem solid #0072f5;
        span:nth-child(1) {
          font-size: 0.66rem;
          font-weight: 500;
          color: #0072f5;
        }
        span:nth-child(2) {
          font-size: 0.49rem;
          color: #0072f5;
        }
        > img {
          position: absolute;
          bottom: 0.1rem;
          right: 0.1rem;
          width: 0.66rem;
          height: 0.66rem;
        }
      }
    }
    .aircraft-tips {
      display: flex;
      align-items: center;
      margin-bottom: 0.45rem;
      span {
        display: flex;
        position: relative;
        font-size: 0.49rem;
        padding: 0 0.39rem;
        color: rgba(22, 43, 64, 0.65);
        &::after {
          position: absolute;
          top: 0.15rem;
          right: 0rem;
          content: "";
          width: 0.02rem;
          height: 0.49rem;
          background: #dcdee0;
        }
      }
    }
  }
  .chunk ::v-deep {
    .time {
      font-size: 1.2rem;
    }
    .item-time {
      border: none;
    }
  }
  .chunk ::v-deep {
    .item-time {
      margin-bottom: 0.82rem;
    }
    .van-cell {
      padding: 0.62rem 0 !important;
      .van-field__label span {
        font-size: 0.66rem;
        color: rgba(22, 43, 64, 0.65);
      }
    }
    .van-cell__title span {
      text-align: left;
      font-size: 0.66rem;
      color: rgba(22, 43, 64, 0.65);
    }
    .van-cell::after {
      border: none;
    }
    .van-hairline--top-bottom::after,
    .van-hairline-unset--top-bottom::after {
      border-color: #ececec !important;
    }
    .van-cell__value span,
    .van-cell__right-icon {
      font-size: 0.66rem;
      color: #162b40;
    }
    .van-picker__cancel,
    .van-field__control,
    .van-picker__confirm,
    .van-picker__columns {
      font-size: 0.66rem !important;
    }
  }
  .submit {
    cursor: pointer;
    width: 13.09rem;
    height: 2.05rem;
    line-height: 2.05rem;
    margin: 0.57rem auto;
    text-align: center;
    background: #0072f5;
    box-shadow: 0rem 0.25rem 0.49rem 0rem rgba(0, 114, 245, 0.15);
    border-radius: 0.33rem;
    > span {
      font-size: 0.74rem;
      color: #ffffff;
    }
  }

  .line {
    width: 100%;
    height: 0.02rem;
    border: 0.02rem solid #f2f5f5;
  }
}
</style>