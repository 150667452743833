<template>
  <van-action-sheet v-model="show" title="订票操作确认" @close="$emit('close')">
    <div class="content">
      <div class="group-form">
        <div class="form-item">
          <span class="label require">操作人姓名</span>
          <van-field
            class="input"
            v-model="name"
            placeholder="请输入操作人姓名"
          />
        </div>
        <div class="form-item">
          <span class="label require">操作人手机号</span>
          <van-field
            class="input"
            v-model="phone"
            placeholder="请输入您的手机号"
          />
        </div>
        <div class="form-item">
          <span class="label require">验证码</span>
          <van-field class="input" v-model="code" placeholder="请输入验证码">
            <template #button>
              <van-button
                size="small"
                type="info"
                :disabled="isCountdown"
                @click="sendCode"
                >{{ codeMessage }}</van-button
              >
            </template>
          </van-field>
        </div>
        <div class="form-item">
          <span class="label">备注</span>
          <van-field class="input" v-model="remark" placeholder="请输入备注" />
        </div>
        <div class="line"></div>
        <div class="submit" @click="submit">
          <span>确定</span>
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  props: {
    show: {},
    type: {}
  },
  data() {
    return {
      name: '',
      phone: '',
      code: '',
      password: '',
      smsId: '', //验证码ID
      codeMessage: '获取验证码',
      Interval: null,
      timer: 60,
      isCountdown: false,
      remark: '',
    }
  },
  methods: {
    // 验证码登录
    submit() {
      if (!this.name) {
        this.$toast('请输入操作人姓名')
        return
      }
      if (!this.phone) {
        this.$toast('请输入操作人手机号')
        return
      }
      if (!this.code) {
        this.$toast('请输入验证码')
        return
      }
      let data = {
        name: this.name,
        code: this.code,
        phone: this.phone,
        remark: this.remark,
        smsId: this.smsId
      }
      this.$emit('handleOperation', data)
    },
    // 获取短信验证码
    sendCode() {
      let _this = this
      if (_this.isCountdown) return false
      const telReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!telReg.test(_this.phone)) {
        _this.$toast('请输入正确的手机号')
        _this.codeMessage = '获取验证码'
        return false
      }
      _this
        .$Req('common/sendSms', {
          phone: _this.phone,
          channel: _this.type
        })
        .then(data => {
          this.smsId = data.smsId
          console.log('倒计时', data)
          _this.Interval = setInterval(function () {
            if (_this.timer <= 0) {
              _this.timer = 60
              clearInterval(_this.Interval)
              _this.codeMessage = '获取验证码'
              _this.isCountdown = false
            } else {
              _this.timer--
              _this.codeMessage = `重新获取(${_this.timer}s)`
              _this.isCountdown = true
            }
          }, 1000)
        })
        .catch(err => { })
    },
  }
}
</script>

<style lang="scss" scoped>
.group-form {
  background: #ffffff;
  padding: 1.31rem 0 0.82rem 0;
  .form-item {
    position: relative;
    margin-bottom: 0.98rem;
    padding: 0 0.98rem;
    .label {
      position: absolute;
      top: -0.4rem;
      left: 1.74rem;
      padding: 0 0.25rem 0 0.7rem;
      font-size: 0.66rem;
      color: #162b40;
      z-index: 1;
      background: #fff;
    }
  }
  .require {
    position: relative;
    &::after {
      position: absolute;
      left: 0.2rem;
      top: 0.1rem;
      content: "*";
      font-size: 1rem;
      color: #f82f45;
    }
  }

  .input {
    display: flex;
    align-items: center;
    height: 2.38rem;
    border-radius: 0.41rem;
    border: 0.04rem solid #e8e8e8;

    ::v-deep {
      .van-field__control {
        font-size: 0.66rem !important;
      }
      .van-button {
        width: 5.33rem;
        height: 1.64rem;
        border-radius: 0.25rem;
        font-size: 0.57rem;
        color: #ffffff;
      }
    }
  }
  .line {
    background: rgba(236, 236, 236, 1);
    width: 100% !important;
    height: 1px;
    margin-bottom: 0.33rem;
  }
  .submit {
    cursor: pointer;
    width: 90%;
    height: 1.64rem;
    margin: 0.33rem auto;
    text-align: center;
    line-height: 1.64rem;
    background: #0072f5;
    border-radius: 0.41rem;
    > span {
      font-size: 0.66rem;
      color: #ffffff;
    }
  }
}
</style>