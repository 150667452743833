<template>
  <div class="booking-record">
    <div class="record-header">
      <Navbar :title="title" :bgColor="'transparent'"></Navbar>
    </div>
    <div class="content">
      <record-tab :activeIndex="activeIndex" @click="changeTab"></record-tab>
      <template v-if="validList.length">
        <div class="record-list">
          <div class="title"><span></span>生效中</div>
        </div>
        <list-item
          v-for="(item, index) in validList"
          :key="index"
          :item="item"
          :status="item.status"
          :type="item.trafficType"
          :isLast="index == validList.length - 1"
          :statusMaping="statusMaping"
          :dictsCodeMap="dictsCodeMap"
          @handleStatus="handletTicket"
        ></list-item>
      </template>
      <template v-if="inValidList.length">
        <div class="record-list">
          <div class="title"><span></span>已失效</div>
        </div>
        <list-item
          v-for="(item, index) in inValidList"
          :key="index"
          :item="item"
          :status="item.status"
          :dictsCodeMap="dictsCodeMap"
          :type="item.trafficType"
          :isLast="index == inValidList.length - 1"
          :statusMaping="statusMaping"
          @handleStatus="handletTicket"
        ></list-item>
      </template>
      <noData
        v-if="validList.length == 0 && inValidList.length == 0"
        :text="'暂无订票记录'"
      ></noData>
    </div>
  </div>
</template>

<script>
import Navbar from '@components/Common/Navbar'
import { mapGetters, mapState } from 'vuex'
import RecordTab from './components/RecordTab.vue'
import ListItem from '@components/Common/ListItem.vue'
import noData from '@components/Common/NoData.vue'
export default {
  components: {
    Navbar,
    RecordTab,
    ListItem,
    noData
  },
  computed: {
    ...mapGetters(['contentWidth', 'contentLeft']),
    ...mapState('user', ['guestId'])
  },
  data() {
    return {
      title: '订票记录',
      activeIndex: 1,
      validList: [],
      inValidList: [],
      dictsCodeMap: {}
    }
  },
  mounted() {
    this.getDict()
    this.getData()
  },
  methods: {
    // 获取字典
    getDict() {
      this.$store.dispatch('getDicts').then(res => {
        this.dictsCodeMap = res
      })
    },
    // 时间排序
    sortedList(list) {
      if (!list.length) return []
      return list.sort((a, b) => {
        return new Date(`${a.dateTime} ${a.startTime}`).getTime() - new Date(`${b.dateTime} ${b.startTime}`).getTime();
      })
    },
    getData() {
      this.validList = []
      this.inValidList = []
      this.$Req('common/ticketsRecord', {
        type: this.activeIndex,
        guestId: this.guestId
      }).then(res => {
        this.validList = this.sortedList(res.validList)
        this.inValidList = this.sortedList(res.inValidList)
        this.$hideLoading()
      })
    },
    changeTab(index) {
      this.activeIndex = index
      this.getData()
    },
    handletTicket(data) {
      console.log(data, '--------')
      if (data.type == 6) {
        this.$router.push({
          path: '/booking',
          query: {
            title: '申请改签',
            type: this.activeIndex,
            ticketId: data.ticketsId,
            ticketStatus: '30'
          }
        })
      } else {
        let params = {
          ticketId: data.ticketsId,
          actionType: data.type
        }
        console.log(params, data)
        this.$Req('common/dealTickets', params).then(res => {
          this.getData()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.booking-record {
  display: flex;
  flex-direction: column;
}
.record-header {
  width: 100%;
  height: 10.26rem;
  background: linear-gradient(180deg, #e1ecff 0%, #f3f5f7 100%);
}
.content {
  margin-top: -8rem;
  padding: 0 0.33rem;
  flex: 1;
  overflow: auto;
  .record-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    font-size: 0.66rem;
    font-weight: 500;
    color: #162b40;
    .title {
      display: flex;
      align-items: center;
      height: 1.81rem;
      padding-left: 0.41rem;
      > span {
        display: inline-block;
        width: 0.25rem;
        height: 0.25rem;
        background: #162b40;
        border-radius: 100%;
        margin-right: 0.25rem;
      }
    }
  }
}
</style>