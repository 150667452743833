export default class RequestProxy {
    constructor() {

    }

    bindService(service) {
        service.interceptors.request.use(this._onRequest.bind(this), this._onError.bind(this))
        service.interceptors.response.use(this._onResponse.bind(this), this._onError.bind(this))

        return this
    }

    _onRequest(config) {
        return this.onRequest ? this.onRequest(config) : config
    }

    _onResponse(response) {

        function makeError() {
            return this._onError(response)
        }

        const {
            status
        } = response
        if (status !== 200) return this._onError(response)
        return this.onResponse ? this.onResponse(response, makeError.bind(this)) : response
    }

    _onError(info) {
        const isError = info instanceof Error
        const error = ['message', 'status'].reduce((error, key) => (error[key] = isError ? info[key] : info.data[key], error), {
            response: isError ? undefined : info,
            message: 'Error'
        })
        return this.onError ? this.onError(error) : Promise.reject(error)
    }
}