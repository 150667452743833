import host from '@request/host'

function isPC() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    const bIsMidp = sUserAgent.match(/midp/i) == "midp";
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    const bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    const bIsAndroid = sUserAgent.match(/android/i) == "android";
    const bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    const bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile"
    const bIsXiaoMi = sUserAgent.match(/xiaoMi/i) == 'xiaomi'
    const bIsHuaWei = sUserAgent.match(/huawei/i) == 'huawei'

    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM || bIsXiaoMi || bIsHuaWei || isMiniprogram()) {
        return false
    } else {
        return true
    }
}

function isMiniprogramPc() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    const bIsMidp = sUserAgent.match(/midp/i) == "midp";
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    const bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    const bIsAndroid = sUserAgent.match(/android/i) == "android";
    const bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    const bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    const bIsXiaoMi = sUserAgent.match(/xiaoMi/i) == 'xiaomi'
    const bIsHuaWei = sUserAgent.match(/huawei/i) == 'huawei'

    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM || bIsXiaoMi || bIsHuaWei) {
        return false
    } else {
        if (isMiniprogram()) { return true } else { return false }

    }
}
function getDevice() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    if (isIOS()) {
        return 'ios'
    } else if (isAndroid()) {
        return 'android'
    } else if (isPC()) {
        if (/macintosh|mac os x/i.test(navigator.userAgent)) {
            return 'mac'
        } else {
            return 'window'
        }

    } else if (bIsIpad) {
        return 'iPad'
    } else {
        return 'rests'
    }
}

function isSafari() {
    return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
}

function isIOS() {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

function isAndroid() {
    return navigator.userAgent.indexOf('Android') > -1
}

function isProduct() {
    return !host.env
}

function isIE() {
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
        return true;
    } else {
        return false;
    }
}

// 1.微信移动端 2.微信PC 3.非微信
function isWechat() {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        if (isPC()) return 2
        return 1
    } else {
        return 3
    }
}

function isWXWork() {
    return /wxwork/i.test(navigator.userAgent)
}

function getWechatVersion() {
    const wechat_info = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)
    if (!wechat_info) {
        return 0
    } else {
        return wechat_info[1]
    }
}

function isChinese() {
    const language = navigator.language || navigator.userLanguage
    return /zh-/.test(language.toLowerCase())
}

function androidModel() {
    const isAndroid = /android/i.test(navigator.userAgent);
    if (isAndroid) {
        const ua = navigator.userAgent.toLowerCase();
        const index = ua.indexOf("android");
        const androidVersion = parseFloat(ua.slice(index + 8));
        if (androidVersion >= 4.2) {
            // 获取设备型号
            // return ua.split(';')[2].split(' ')[1];
            return ua.toLowerCase()
        } else {
            return 'Android 版本过低，不支持获取设备型号';
        }
    } else {
        return '非 Android 设备，无法获取设备型号';
    }  
}
function isXiaoMi() {
   return /XiaoMi/i.test(navigator.userAgent)
    
}
function isMiniprogram() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("miniprogram") != -1) {
        return true
    } else { return false }
}

export default {
    isPC,
    isIOS,
    isAndroid,
    isProduct,
    isWechat,
    getWechatVersion,
    isChinese,
    isIE,
    isSafari,
    getDevice,
    isWXWork,
    androidModel,
    isMiniprogram,
    isMiniprogramPc,
    isXiaoMi
}