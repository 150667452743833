<template>
  <div class="headbar" :style="{ background: backgroundColor }">
    <div class="headbar-left">
      <span class="cricle"></span>
      <span>{{ text }}</span>
    </div>
    <div
      class="headbar-right"
      v-if="(text == '来程订票' || text == '返程订票') && isAdd"
      @click="$emit('add')"
    >
      <span>添加订票</span>
      <img src="@assets/add.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    isAdd: {
      type: Boolean,
      default: true
    }
  },

}
</script>

<style lang="scss" scoped>
.headbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 2.63rem;
  padding: 0.41rem 0.33rem;
  border-radius: 0.41rem 0.41rem 0rem 0rem;
  .headbar-left {
    display: flex;
    align-items: center;
    .cricle {
      display: inline-block;
      width: 0.21rem;
      height: 0.21rem;
      background: #ffffff;
      border-radius: 100%;
      margin-right: 0.21rem;
    }
    span {
      font-size: 0.57rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .headbar-right {
    display: flex;
    align-items: center;
    > span {
      font-size: 0.49rem;
      color: rgba(22, 43, 64, 0.85);
      margin-right: 0.16rem;
    }
    > img {
      width: 0.94rem;
      height: 0.94rem;
    }
  }
}
</style>