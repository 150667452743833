import axios from 'axios'

export default class Request {
    constructor(options = {
        baseURL: '',
        timeout: 5000
    }, proxy) {
        this._axios = axios
        this._service = this._axios.create(options)
        this._proxy = proxy.bindService(this._service)
    }

    post(url, params, customConfig) {
        return this._service({
            url,
            customConfig,
            method: 'post',
            data: params,
        })
    }

    get(url, params, customConfig) {
        return this._service({
            url,
            customConfig,
            method: 'get',
            params
        })
    }

    put(url, params, customConfig) {
        return this._service({
            url,
            customConfig,
            method: 'put',
            data: params
        })
    }

    delete(url, params, customConfig) {
        return this._service({
            url,
            customConfig,
            method: 'delete',
            data: params
        })
    }

    getAxios() {
        return this._axios
    }

    getService() {
        return this._service
    }

    getProxy() {
        return this._proxy
    }
}