<template>
  <div class="sidebar">
    <div
      :class="[
        'sidebar-item',
        index == active ? 'active' : '',
        item.index == 0 ? 'schedule-icon' : '',
      ]"
      v-for="(item, index) in list"
      :key="index"
      @click="handleClick(item, index)"
    >
      <template v-if="item">
        <template v-if="item.label == '我的日程'">
          <img src="@assets/schedule_icon.png" alt="" />
          <span>我的日程</span>
        </template>
        <template v-else>
          <div class="sidebar-item-title">{{ item.label }}</div>
          <div class="sidebar-item-information van-multi-ellipsis--l2">
            {{ item.value }}
          </div>
        </template>
      </template>
      <img
        class="bottom-right"
        v-if="
          (active != index && active > index + 1) ||
          (active != index && active < index)
        "
        src="@assets/horn.png"
        alt=""
      />
      <img
        class="top-right"
        v-if="active != index && active + 1 != index"
        src="@assets/horn.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {},
    label: {
      type: Array,

    }
  },
  data() {
    return {
      list: []
    }
  },
  watch: {
    active(val) {
      this.active = val
    },
    label: {
      handler(val) {
        this.list = []
        val.map(item => {
          if (item.isShow) this.list.push(item)
        })
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    handleClick(item, index) {
      if (!item.label) return
      this.$emit('click', index)
    },
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  width: 3.41rem;
  background: #eff2f9;
  .sidebar-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 3.41rem;
    height: 2.95rem;
    background: #ffffff;
    padding: 0.41rem 0.25rem;
    border-radius: 0rem 0.41rem 0.41rem 0rem;
    .sidebar-item-title {
      font-size: 0.57rem;
      font-weight: 500;
      color: #162b40;
    }
    .sidebar-item-information {
      font-size: 0.45rem;
      color: rgba(22, 43, 64, 0.65);
    }
  }
  .schedule-icon {
    align-items: center;
    justify-content: center;
    > img {
      width: 1rem;
      height: 1rem;
    }
    > span {
      margin-top: 0.35rem;
      font-size: 0.49rem;
      font-weight: 500;
      color: #d49b53;
    }
  }
  .sidebar-item:nth-child(1) {
    border-radius: 0rem 0rem 0.41rem 0rem;
  }
  .sidebar-item:last-child {
    border-radius: 0rem 0.41rem 0rem 0rem;
  }
  .active {
    background: rgba(239, 242, 249, 1);
  }
  .bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(270deg);
  }
  .top-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(180deg);
  }
}
</style>