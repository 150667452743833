<template>
  <div class="login">
    <div class="header">
      <span>{{ accountLogin == 1 ? "短信登录" : "密码登录" }}</span>
    </div>
    <div class="warp">
      <div class="cover">
        <img
          :src="project.backgroundImg || require('@assets/default_bg.png')"
          alt=""
        />
      </div>
      <div class="warp-content">
        <div class="group-form" v-if="accountLogin == 1">
          <div class="form-item">
            <span class="label">手机号</span>
            <van-field
              class="input"
              v-model="account"
              placeholder="请输入您的手机号"
            />
          </div>
          <div class="form-item">
            <span class="label">验证码</span>
            <van-field class="input" v-model="code" placeholder="请输入验证码">
              <template #button>
                <van-button
                  size="small"
                  type="info"
                  :disabled="isCountdown"
                  @click="sendCode"
                  >{{ codeMessage }}</van-button
                >
              </template>
            </van-field>
          </div>
          <!-- <van-checkbox v-model="isAgree" shape="square" icon-size="14px">
          <div class="checkbox-text">
            登录即代表您同意平台 <span>《用户协议》</span>和
            <span>《隐私政策》</span>
          </div>
        </van-checkbox> -->
          <div
            class="submit"
            @click="checkLogin"
            :style="{ background: isLogin ? 'rgba(0, 114, 245, 1)' : '' }"
          >
            <span>登录</span>
          </div>
        </div>
        <div class="group-form" v-if="accountLogin == 2">
          <div class="form-item">
            <span class="label">手机号</span>
            <van-field
              class="input"
              v-model="account"
              placeholder="请输入您的手机号"
            />
          </div>
          <div class="form-item">
            <span class="label">密码</span>
            <van-field
              class="input"
              type="password"
              v-model="password"
              placeholder="请输入密码"
            >
            </van-field>
          </div>
          <!-- <van-checkbox v-model="isAgree" shape="square" icon-size="14px">
          <div class="checkbox-text">
            登录即代表您同意平台 <span>《用户协议》</span>和
            <span>《隐私政策》</span>
          </div>
        </van-checkbox> -->
          <div
            class="submit"
            :style="{ background: isLogin ? 'rgba(0, 114, 245, 1)' : '' }"
            @click="checkLogin"
          >
            <span>登录</span>
          </div>
        </div>
        <div class="switch-login-type" @click="switchLogin">
          <span>{{ accountLogin == 2 ? "短信登录" : "密码登录" }}</span>
        </div>
      </div>
    </div>
    <div class="srogen" v-if="project.hideLogo != 1">
      <img src="@assets/logo.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@utils/index.js'
export default {
  data() {
    return {
      account: '',
      code: '',
      password: '',
      smsId: '', //验证码ID
      isAgree: true,
      accountLogin: 1,
      codeMessage: '获取验证码',
      Interval: null,
      timer: 60,
      isCountdown: false,
      project: {}
    }
  },
  computed: {
    isLogin() {
      return this.accountLogin == 1
        ? this.account && this.code && this.isAgree
        : this.account && this.password && this.isAgree
    },
    ...mapGetters(['contentWidth', 'contentLeft'])
  },
  mounted() {
    this.$hideLoading()
    this.getProjects()
  },
  methods: {
    // 获取项目信息
    getProjects() {
      this.$Req('common/projects', {
        projectCode: this.$route.query.projectCode
      })
        .then(res => {
          this.project = res
          this.$store.commit('setProject', this.project)
          sessionStorage.setItem(
            'minDate',
            utils.dealFormData(this.project.ticketStartTime, 'Y-m-d h:00:00')
          )
          sessionStorage.setItem(
            'maxDate',
            utils.dealFormData(this.project.ticketEndTime, 'Y-m-d') + ' 23:59:59'
          )
          this.$hideLoading()
        })
        .catch(_ => {
          this.$hideLoading()
        })
    },
    switchLogin() {
      if (this.accountLogin == 1) this.accountLogin = 2
      else this.accountLogin = 1
    },
    // 账号登录
    acountLogin() {
      this.$Req('login/login', {
        method: 1,
        account: this.account,
        password: this.password,
        channel: 1,
        projectCode: this.$route.query.projectCode
      }).then(res => {
        this.loginSuccess(res)
      })
    },
    // 登录成功处理事件
    loginSuccess(data) {
      this.$store.commit('user/setToken', data.token)
      this.$store.commit('user/setGuestId', data.id)
      this.$store.commit('setProjectId', data.projectId)
      localStorage.setItem('projectCode', this.$route.query.projectCode)
      this.$router
        .push({
          path: '/'
        })
        .catch(() => { })
    },
    //短信验证码登陆
    checkCodeLogin() {
      //登录接口
      if (!this.isAgree) {
        this.$toast('请选择同意平台服务协议和隐私政策')
        return false
      }
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      let reg = /^[\d]{6}$/
      if (!reg_tel.test(this.account)) {
        this.$toast('请输入正确的手机号')
        return
      }
      if (!this.code) {
        this.$toast('请填写验证码')
        return
      }
      if (!reg.test(this.code)) {
        this.$toast('验证码长度错误')
        return
      }
      if (reg_tel.test(this.account) && reg.test(this.code) && this.isAgree == true) {
        this.codeLogin()
      } else {
        this.$toast('用户名或验证码错误')
        return
      }
    },
    //账号登录
    checkAcountLogin() {
      //登录接口
      if (!this.isAgree) {
        this.$toast('请选择同意平台服务协议和隐私政策')
        return false
      }
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!reg_tel.test(this.account)) {
        this.$toast('请输入正确的手机号')
        return
      }
      if (this.password == '') {
        this.$toast('请输入密码')
        return false
      }
      if (this.account != '' && this.password != '') {
        if (this.account.length > 0 && this.password) {
          this.acountLogin()
        } else {
          this.$toast('密码长度错误')
        }
      }
    },
    // 验证码登录
    codeLogin() {
      this.$Req('login/login', {
        method: 2,
        account: this.account,
        password: this.password,
        code: this.code,
        smsId: this.smsId,
        channel: 1,
        projectCode: this.$route.query.projectCode
      }).then(res => {
        this.loginSuccess(res)
      })
    },
    // 获取短信验证码
    sendCode() {
      let _this = this
      if (_this.isCountdown) return false
      const telReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!telReg.test(_this.account)) {
        _this.$toast('请输入正确的手机号')
        _this.codeMessage = '获取验证码'
        return false
      }
      _this
        .$Req('login/login?action=sendSms', {
          phone: _this.account,
          projectCode: _this.$route.query.projectCode,
          channel: 1
        })
        .then(data => {
          this.smsId = data.smsId
          console.log('倒计时', data)
          _this.Interval = setInterval(function () {
            if (_this.timer <= 0) {
              _this.timer = 60
              clearInterval(_this.Interval)
              _this.codeMessage = '获取验证码'
              _this.isCountdown = false
            } else {
              _this.timer--
              _this.codeMessage = `重新获取(${_this.timer}s)`
              _this.isCountdown = true
            }
          }, 1000)
        })
        .catch(err => { })
    },
    // 登录验证方式
    checkLogin() {
      if (this.accountLogin == 2) {
        this.checkAcountLogin()
      } else {
        this.checkCodeLogin()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  overflow: auto;
  .header {
    position: relative;
    width: 100%;
    height: 10.26rem;
    background: url(~@assets/login_bg.png) no-repeat center/cover;
    > span {
      position: absolute;
      top: 2.97rem;
      left: 0.82rem;
      font-size: 1.15rem;
      color: #0f182e;
    }
  }
  .warp {
    padding: 0 0.41rem;
    position: relative;
    width: 100%;
    margin-top: -4.62rem;
    padding-bottom: 1.66rem;

    z-index: 1;
    .cover {
      width: 100%;
      height: 2.75rem;

      > img {
        width: 100%;
        height: 100%;
        border-radius: 0.49rem 0.49rem 0 0;
      }
    }
    .warp-content {
      background: #fff;
      padding-bottom: 1rem;
      border-radius: 0.49rem;
    }
    .group-form {
      background: #ffffff;
      padding: 1.31rem 0.57rem 0.82rem 0.57rem;
      .form-item {
        position: relative;
        margin-bottom: 0.98rem;
        .label {
          position: absolute;
          top: -0.4rem;
          left: 0.74rem;
          padding: 0 0.25rem;
          font-size: 0.66rem;
          color: #162b40;
          z-index: 1;
          background: #fff;
        }
      }
      .input {
        display: flex;
        align-items: center;
        height: 2.38rem;
        border-radius: 0.41rem;
        border: 0.04rem solid #e8e8e8;

        ::v-deep {
          .van-field__control {
            font-size: 0.66rem !important;
          }
          .van-button {
            width: 5.33rem;
            height: 1.64rem;
            border-radius: 0.25rem;
            font-size: 0.57rem;
            color: #ffffff;
          }
        }
      }
      .checkbox-text {
        font-size: 0.49rem;
        color: #848d94;
        > span {
          color: rgba(0, 114, 245, 1);
        }
      }
      .submit {
        cursor: pointer;
        margin-top: 0.57rem;
        width: 100%;
        height: 2.05rem;
        text-align: center;
        line-height: 2.05rem;
        background: rgba(0, 114, 245, 0.45);
        border-radius: 0.41rem;
        > span {
          font-size: 0.66rem;
          color: #ffffff;
        }
      }
    }
    .switch-login-type {
      margin: 0.4rem 0;
      width: 100%;
      text-align: center;
      > span {
        font-size: 0.66rem;
        color: #0f182e;
      }
    }
  }
  .srogen {
    width: 9.44rem;
    height: 1.64rem;
    margin: 0 auto;
    margin-top: 2.95rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>