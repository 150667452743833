<template>
  <div class="item-time">
    <div class="start">
      <span class="time">{{ item.startTime }}</span>
      <span class="address"
        >{{ item.startStation }}{{ item.startTerminal }}</span
      >
    </div>
    <div class="center">
      <span>{{ item.costTime }}</span>
      <img src="@assets/route_line.png" alt="" />
      <span>{{ item.airline }}{{ item.trainNo || item.flightNo }}</span>
    </div>
    <div class="end">
      <span class="time">{{ item.endTime }}</span>
      <span class="address">{{ item.endStation }}{{ item.endTerminal }}</span>
      <span class="tips" v-if="item.day >= 1">+{{ item.day }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {}
  }
}
</script>

<style lang="scss" scoped>
.item-time {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.41rem;
  border-bottom: 1px solid #ececec;
  .start,
  .end {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .time {
      font-size: 0.98rem;
      font-weight: 600;
      color: #162b40;
    }
    .address {
      margin-top: 0.28rem;
      font-size: 0.49rem;
      color: rgba(22, 43, 64, 0.85);
    }
    .tips {
      position: absolute;
      top: -0.5rem;
      right: 0;
      font-size: 0.45rem;
      color: rgba(22, 43, 64, 0.85);
    }
  }
  .start {
    align-items: flex-start;
  }
  .end {
    align-items: flex-end;
  }
  .center {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > img {
      width: 3.08rem;
      height: 0.15rem;
      margin: 0.1rem 0;
    }
    > span {
      font-size: 0.49rem;
      color: #162b40;
    }
  }
}
</style>