<template>
  <van-tabbar
    v-model="tabbarIndex"
    v-if="isShowTabber"
    @change="onChange"
    :border="false"
    :style="{
      width: contentWidth,
      zIndex: 2001,
      left: contentLeft,
      boxShadow: ' 0 0 10px 0 hsla(0,6%,58%,.3)',
    }"
  >
    <van-tabbar-item>
      <template #icon="props">
        <div class="tabbar-item">
          <img
            :src="
              props.active
                ? require('@assets/home_selected.png')
                : require('@assets/home.png')
            "
          />
          <span class="title">接待</span>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item>
      <template #icon="props">
        <div class="tabbar-item">
          <img
            class="icon"
            :src="
              props.active
                ? require('@assets/my_selected.png')
                : require('@assets/my.png')
            "
          />
          <span class="title">我的</span>
        </div>
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    isShowTabber() {
      const { path } = this.$route
      return path == '/' || path == '/my'
    },
    ...mapGetters(['contentWidth', 'contentLeft']),
    tabbarIndex() {
      return this.$store.state.common.tabbarIndex
    },
  },
  methods: {
    onChange(e) {
      console.log(e, '切换菜单')
      if (e == 1) {
        this.$router.push({
          path: '/my'
        })
      } else {
        this.$router.push({
          path: '/'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.14rem 0;
  > img {
    width: 1.15rem;
    height: 1.15rem;
  }
  .title {
    margin-top: 0.12rem;
    font-size: 0.45rem;
  }
}
</style>