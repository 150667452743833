<template>
    <div class="personal-information">
        <div class="personal-header">
            <Navbar :title="title" :bgColor="'transparent'"></Navbar>
        </div>
        <div class="content">
            <div class="personal">
                <van-cell-group>
                    <van-cell title="头像" center>
                        <template #right-icon is-link>
                            <div class="upload">
                                <input
                                    type="file"
                                    style="display: none"
                                    ref="file"
                                    accept="image/*"
                                    @click="
                                        e => {
                                            e.target.value = ''
                                        }
                                    "
                                    @change="onUploadHead"
                                />
                                <img
                                    :src="profilePic || require('@assets/default_photo.png')"
                                    @click="uploadHead"
                                    alt=""
                                />
                            </div>
                            <van-icon name="arrow" />
                        </template>
                    </van-cell>
                    <van-field
                        @click="$toast('真实姓名和手机号不可修改')"
                        input-align="right"
                        v-model="name"
                        label="真实姓名"
                        readonly
                        placeholder="请输入用户名"
                    />
                    <van-cell title="性别" is-link :value="sex" @click="isSex = true" />
                    <van-field
                        input-align="right"
                        v-model="phone"
                        label="手机号"
                        readonly
                        @click="$toast('真实姓名和手机号不可修改')"
                        placeholder="请输入手机号"
                    />
                    <van-cell
                        title="证件类型"
                        is-link
                        @click="isIdType = true"
                        :value="utils.getDictionaryText(cardType, columns)"
                    />
                    <van-field
                        input-align="right"
                        v-model="cardNo"
                        label="证件号码"
                        placeholder="用于身份核验，请正确填写"
                    />
                </van-cell-group>
            </div>
            <div class="works">
                <van-cell-group>
                    <van-cell
                        title="所在省份"
                        @click="isProvince = true"
                        is-link
                        :value="areaName"
                    />
                    <van-field
                        input-align="right"
                        v-model="company"
                        label="所在单位"
                        placeholder="请输入所在单位"
                    />
                    <van-field
                        input-align="right"
                        v-model="department"
                        label="所在部门"
                        placeholder="请输入所在部门"
                    />
                    <van-field
                        input-align="right"
                        v-model="job"
                        label="职位"
                        placeholder="请输入职位"
                    />
                </van-cell-group>
            </div>
        </div>
        <div class="submit" @click="submit">
            <span>提交修改</span>
        </div>
        <!-- 性别选择 -->
        <van-popup
            v-model="isSex"
            get-container="body"
            position="bottom"
            class="my-picker"
            style="border-radius: 0.7rem 0.7rem 0 0"
            :style="{ width: contentWidth, left: contentLeft }"
        >
            <van-picker
                show-toolbar
                :columns="sexColumns"
                @confirm="onSexConfirm"
                @cancel="isSex = false"
            />
        </van-popup>
        <!-- 身份信息 -->
        <van-popup
            v-model="isIdType"
            position="bottom"
            class="my-picker"
            style="border-radius: 0.7rem 0.7rem 0 0"
            :style="{ width: contentWidth, left: contentLeft }"
        >
            <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onIdConfirm"
                @cancel="isIdType = false"
            />
        </van-popup>
        <!-- 所在省份 -->
        <van-popup
            v-model="isProvince"
            position="bottom"
            class="my-picker"
            style="border-radius: 0.7rem 0.7rem 0 0"
            :style="{ width: contentWidth, left: contentLeft }"
        >
            <van-picker
                show-toolbar
                :columns="provinceColumns"
                @confirm="onProvinceConfirm"
                @cancel="isProvince = false"
            />
        </van-popup>
    </div>
</template>

<script>
import Navbar from '@components/Common/Navbar'
import { mapGetters, mapState } from 'vuex'
import utils from '@utils/index.js'
export default {
    components: {
        Navbar
    },
    computed: {
        ...mapGetters(['contentWidth', 'contentLeft']),
        ...mapState('user', ['guestId'])
    },
    data() {
        return {
            utils,
            title: '个人信息',
            isSex: false,
            name: '',
            sex: '男',
            phone: '',
            company: '',
            profilePic: '',
            department: '',
            job: '',
            areaName: '',
            areaCode: '',
            appendState: '',
            sexColumns: [
                {
                    text: '男'
                },
                {
                    text: '女'
                }
            ],
            isIdType: false,
            cardNo: '',
            cardType: '身份证',
            columns: [],
            isProvince: false,
            province: '',
            provinceColumns: [],
            userInfo: {}
        }
    },
    mounted() {
        if (!localStorage.getItem('token')) {
            this.$router.push('/login')
            return
        }
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        console.log(this.userInfo.sex, 'this.userInfo')
        this.name = sessionStorage.getItem('name')
        this.phone = this.userInfo.phone
        this.company = this.userInfo.company
        this.department = this.userInfo.department
        this.job = this.userInfo.job
        this.cardType = this.userInfo.cardType
        this.cardNo = this.userInfo.cardNo
        this.appendState = this.userInfo.appendState
        this.areaCode = this.userInfo.area
        this.profilePic = this.userInfo.profilePic
        if (this.userInfo.sex) {
            this.sex = this.userInfo.sex == 1 ? '男' : '女'
        } else {
            this.sex = ''
        }
        this.getDict()
        this.getAreaCodes()
        this.$hideLoading()
    },
    methods: {
        getDict() {
            this.$Req('common/getDicts', { dictName: 'card_type' }).then(data => {
                this.columns = data
                this.columns.map(item => {
                    item.text = item.codeDesc
                    item.value = item.code
                })
            })
        },
        getAreaCodes() {
            this.$Req('common/AreaCodes', {}).then(res => {
                this.provinceColumns = res
                this.provinceColumns.map(item => {
                    item.text = item.name
                    item.value = item.code
                })
                this.provinceColumns.map(item => {
                    if (this.areaCode == item.code) {
                        this.areaName = item.name
                    }
                })
            })
        },
        submit() {
            let params = {
                guestId: this.guestId || localStorage.getItem('guestId'),
                phone: this.phone,
                name: this.name,
                profilePic: this.profilePic,
                sex: this.sex == '男' ? 1 : 2,
                cardType: this.cardType,
                cardNo: this.cardNo,
                area: this.areaCode,
                company: this.company,
                department: this.department,
                job: this.job,
                state: 1,
                appendState: this.appendState
            }
            console.log(
                this.provinceColumns.filter(item => {
                    return this.areaName == item.text
                }).code,
                'this.area'
            )
            this.$Req('common/edit', params).then(res => {
                console.log(res, '00000000000')
                this.$router.push({
                    path: '/my'
                })
            })
        },
        onSexConfirm(data) {
            console.log(data)
            this.isSex = false
            this.sex = data.text
        },
        onIdConfirm(data) {
            console.log(data)
            this.isIdType = false
            this.cardType = data.code
        },
        onProvinceConfirm(data) {
            this.isProvince = false
            this.areaName = data.text
            this.provinceColumns.map(item => {
                if (this.areaName == item.name) {
                    this.areaCode = item.code
                }
            })
            console.log(this.areaName, 'onProvinceConfirm')
        },
        uploadHead() {
            this.$refs.file.dispatchEvent(new MouseEvent('click'))
        },
        onUploadHead(e) {
            // 上传头像
            this.$Req('upload/imageUpload', {
                file: this.$refs.file.files[0]
            })
                .then(res => {
                    let timestamp = new Date().getTime()
                    let _this = this
                    setTimeout(function () {
                        _this.profilePic = `https://file.docbook.com.cn/${res}?${timestamp}`
                        console.log(_this.profilePic, '------dsasdasdas---')
                    }, 0)
                })
                .catch(e => {
                    this.$toast(e)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.personal-header {
    width: 100%;
    height: 6.56rem;
    background: linear-gradient(180deg, #e1ecff 0%, #f2f5f7 100%);
}
.content {
    padding: 0 0.33rem;
    margin-top: -4.21rem;
    .personal {
        width: 100%;
        background: #fff;
        border-radius: 0.49rem;
        padding: 0 0.57rem;
        .upload img {
            width: 2.46rem;
            height: 2.46rem;
            margin-right: 0.41rem;
        }
    }
    .works {
        width: 100%;
        background: #fff;
        border-radius: 0.49rem;
        padding: 0 0.57rem;
        margin-top: 0.41rem;
    }
}
::v-deep {
    .van-cell {
        padding: 0.49rem 0;
    }
    .van-cell::after {
        right: 0;
        left: 0;
    }
    .van-cell__value span {
        font-size: 0.66rem;
        color: #162b40;
    }
    .van-cell__title span {
        font-size: 0.66rem;
        color: rgba(22, 43, 64, 0.65);
    }
    .van-picker__cancel,
    .van-field__control,
    .van-picker__confirm,
    .van-picker__columns {
        font-size: 0.66rem !important;
    }
}
.submit {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.33rem;
    margin-top: 0.82rem;
    > span {
        display: flex;
        width: 100%;
        height: 2.05rem;
        background: #0072f5;
        box-shadow: 0rem 0.25rem 0.49rem 0rem rgba(0, 114, 245, 0.15);
        border-radius: 0.33rem;
        align-items: center;
        justify-content: center;
        font-size: 0.66rem;
        color: #ffffff;
    }
}
</style>