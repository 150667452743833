
export default class RequestAdapter {
    constructor(req, loadReq) {
        this.req = req
        this.loadReq = loadReq
        this.name = this.__proto__.constructor.name
        this.tag = ''
    }
    handleInfo() {
        throw new Error(`${this.name} 只能处理 Request (放在最后一层)`)
    }
    handleRequest() {
        throw new Error(`${this.name} 不能处理 Request`)
    }
}

export class RequestAdapterGet extends RequestAdapter {
    constructor(req, loadReq) {
        super(req, loadReq)

        this.tag = 'GET'
    }

    handleInfo(info) {
        return {
            ...info,
            method: 'get',
        }
    }

    handleRequest({
        url,
        args,
        config
    }) {
        return this.req.get(url, args, config)
    }
}

export class RequestAdapterPost extends RequestAdapter {
    constructor(req, loadReq) {
        super(req, loadReq)

        this.tag = 'POST'
    }

    handleInfo(info) {
        return {
            ...info,
            method: 'post',
        }
    }

    handleRequest({
        url,
        args,
        config
    }) {
        return this.req.post(url, args, config)
    }
}

export class RequestAdapterPut extends RequestAdapter {
    constructor(req, loadReq) {
        super(req, loadReq)

        this.tag = 'PUT'
    }

    handleInfo(info) {
        return {
            ...info,
            method: 'put',
        }
    }

    handleRequest({
        url,
        args,
        config
    }) {
        return this.req.put(url, args, config)
    }
}

export class RequestAdapterDelete extends RequestAdapter {
    constructor(req, loadReq) {
        super(req, loadReq)

        this.tag = 'DELETE'
    }

    handleInfo(info) {
        return {
            ...info,
            method: 'delete',
        }
    }

    handleRequest({
        url,
        args,
        config
    }) {
        return this.req.delete(url, args, config)
    }
}

export class RequestAdapterPure extends RequestAdapter {
    constructor(req, loadReq) {
        super(req, loadReq)

        this.tag = 'PURE'
    }

    handleRequest({
        url,
        args,
        config,
        method,
    }) {
        return new Promise((resolve, reject) => {
            this.req.getAxios()[method](url, args).then(res => {
                resolve(res.data)
            }).catch(e => reject(e))
        })
    }
}

export const defaultAdapters = [RequestAdapterGet, RequestAdapterPost, RequestAdapterPut, RequestAdapterDelete, RequestAdapterPure]