import {
  Request,
  RequestProxy,
  RequestParser,
  defaultAdapters
} from './src'

class GATRequest {
  constructor(config) {
    const {
      axios,
      parser,
      proxy,
      adapters = []
    } = config

    this._proxy = proxy || new RequestProxy
    this._req = new Request(axios, this._proxy)
    this._adapters = [...defaultAdapters, ...adapters].map(Adapter => new Adapter(this._req, this.loadRequest.bind(this))).reduce((map, item) => (map[item.tag] = item, map), {})
    this._cache = {}
    this._cacheRes = {}
    if (parser) {
      this._parser = parser instanceof RequestParser ? parser : new RequestParser(parser)
    } else {
      throw new Error('adapter 不能为空')
    }
  }

  onRequest(onRequest) {
    this._proxy.onRequest = onRequest
  }

  onResponse(onResponse) {
    this._proxy.onResponse = onResponse
  }

  onError(onError) {
    this._proxy.onError = onError
  }

  loadRequest(url, args) {

    const [requestPath, query = ''] = url.split(/\?/) || [url]

    let handledInfo = this._cache[requestPath] // 有缓存使用缓存

    if (!handledInfo) {
      const {
        path,
        config
      } = this.getCustomConfig(requestPath)
      const parseInfo = this._parser.get(path)

      if (Object.prototype.toString.call(parseInfo) === '[object Function]') return parseInfo.call(this, this._req, config)

      if (!parseInfo) throw new Error(`没有找到 path: ${path}`)
      handledInfo = this.handleAdapter({
        config,
        ...parseInfo,
      })
      this._cache[requestPath] = handledInfo
    }

    const {
      info,
      adapter
    } = handledInfo

    return new Promise((resolve, reject) => {
        const fullUrl = `${info.url}${query ? '?' + query : ''}`
      if (info.config.cache && this._cacheRes[fullUrl]) return resolve(this._cacheRes[fullUrl])
      adapter.handleRequest({
        args,
        ...info,
        url: fullUrl
      }).then(res => {
        // 需要缓存
        if (info.config.cache) this._cacheRes[fullUrl] = res
        resolve(res)
      }).catch(e => reject(e))
    })
  }

  getCustomConfig(path, autoReplace = true) {
    const ignore = /\*/.test(path)
    const primary = /!/.test(path)
    const cache = /#/.test(path)

    return {
      path: autoReplace ? path.replace(/^(\*|!|#)/, '') : path,
      config: {
        ignore,
        primary,
        cache
      }
    }
  }

  handleAdapter(reqInfo) {
    if (!reqInfo.tags || reqInfo.tags.length === 0) throw new Error('表达式格式错误')
    let info = reqInfo
    for (let i = 0; i < reqInfo.tags.length; i++) {
      const tag = reqInfo.tags[i]
      const adapter = this._adapters[tag]
      if (adapter) {
        if (i === reqInfo.tags.length - 1) {
          return {
            info,
            adapter
          }
        } else {
          info = adapter.handleInfo.call(adapter, info)
        }
      } else throw new Error(`TAG: ${tag} 没有匹配到处理器`)
    }
  }

  install(Vue) {
    Vue.prototype.$Req = this.loadRequest.bind(this)
  }
}

export default GATRequest