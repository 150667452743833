<template>
  <div class="booking">
    <div class="booking-header">
      <Navbar v-if="title" :title="title" :bgColor="'transparent'"></Navbar>
    </div>
    <div class="content">
      <div class="booking-tab">
        <div
          :class="['tab-item', activeIndex == 1 ? ' active' : '']"
          @click="activeIndex = 1"
        >
          <img src="@assets/train.png" alt="" />
          <span>火车票</span>
        </div>
        <div
          :class="['tab-item', activeIndex == 2 ? ' active' : '']"
          @click="activeIndex = 2"
        >
          <img src="@assets/aircraft.png" alt="" />
          <span>飞机票</span>
        </div>
      </div>
      <address-time
        v-if="activeIndex == 1"
        @start="
          isAddress = true;
          isSetOut = 1;
        "
        @end="
          isAddress = true;
          isSetOut = 2;
        "
        @changeAddress="changeAddress"
        @date="isCalendar = true"
        :start="startStation"
        :end="endStation"
        :date="dateTime"
        :week="week"
      ></address-time>
      <address-time
        v-if="activeIndex == 2"
        @start="
          isAddress = true;
          isSetOut = 1;
        "
        @end="
          isAddress = true;
          isSetOut = 2;
        "
        @changeAddress="changeAddress"
        @date="isCalendar = true"
        :start="startStation"
        :end="endStation"
        :date="dateTime"
        :week="week"
      ></address-time>
      <div class="submit" @click="submit">
        <span>查询</span>
      </div>
    </div>
    <!-- 日历 -->
    <van-calendar
      v-model="isCalendar"
      color="#1989fa"
      :show-mark="false"
      :show-confirm="false"
      @select="calendarConfirm"
      :min-date="minDate"
      :max-date="maxDate"
      position="bottom"
      :style="{ width: contentWidth, left: contentLeft }"
    />
    <!-- 选择地区 -->
    <select-address
      v-if="isAddress"
      :showPicker="isAddress"
      :transportation="activeIndex"
      :is-set-out="isSetOut"
      @close="isAddress = false"
      @comfire="comfire"
    ></select-address>
  </div>
</template>

<script>
import Navbar from '@components/Common/Navbar'
import AddressTime from '@components/Common/AddressTime'
import SelectAddress from '@components/Common/SelectAddress'
import { mapGetters, mapState } from 'vuex'
import utils from '@utils/index.js'
export default {
  name: 'booking',
  components: {
    Navbar,
    SelectAddress,
    AddressTime
  },
  data() {
    return {
      title: '',
      activeIndex: 1, // 交通方式type
      isSetOut: 1,
      isCalendar: false,
      isAddress: false,
      dateTime: '',
      week: '',
      startStation: '',
      endStation: '',
      defaulatDate: '',
      project: {},
      minDate: '',
      maxDate: '',
      startCity: '',
      endCity: '',
      endStationCode: '',
      startStationCode: ''
    }
  },
  computed: {
    ...mapGetters(['contentWidth', 'contentLeft']),

  },
  created() {

    this.minDate = new Date(sessionStorage.getItem('minDate'))
    this.maxDate = new Date(sessionStorage.getItem('maxDate'))
  },
  activated() {
    this.title = this.$route.query.type == 1 ? '来程订票' : '返程订票'
  },
  mounted() {
    this.title = this.$route.query.type == 1 ? '来程订票' : '返程订票'
    console.log(this.title, '来程订票返程订票')
    this.dateTime = this.getDay(new Date(this.minDate))
    this.defaulatDate = new Date(this.minDate)
    this.week = this.getWeek(new Date(this.minDate))
    this.$hideLoading()
  },

  methods: {
    changeAddress() {
      const { startStation, endStation, startCity, endCity, startStationCode, endStationCode } = this
      this.startStation = endStation
      this.endStation = startStation
      this.startCity = endCity
      this.endCity = startCity
      this.startStationCode = endStationCode
      this.endStationCode = startStationCode
    },
    // 获取日期
    getDay(date) {
      return (date.getMonth() + 1) + "月" + date.getDate() + "日"
    },
    // 获取周
    getWeek(date) {
      let weeks = ['日', '一', '二', '三', '四', '五', '六']
      let day = new Date(date || new Date()).getDay()
      return `星期${weeks[day]}出发`
    },
    calendarConfirm(date) {
      this.isCalendar = false
      this.defaulatDate = date
      this.dateTime = this.getDay(date)
      this.week = this.getWeek(date)
    },
    // 地址选择
    comfire(data) {
      console.log(data, '地址选择---------')
      if (data.isSetOut == 1) {
        this.startStation = data.stationName
        this.startCity = data.cityName
        this.startStationCode = data.stationCode
      } else {
        this.endStation = data.stationName
        this.endCity = data.cityName
        this.endStationCode = data.stationCode
      }
    },
    // 查询
    submit() {
      if (!this.startStation) {
        this.$toast('请选择出发地')
        return
      } else if (!this.endStation) {
        this.$toast('请选择到达地')
        return
      } else if (this.startStation == this.endStation) {
        this.$toast('始发地跟目的地不能相同')
        return
      }
      if (!this.dateTime) {
        this.$toast('请选择出发时间')
        return
      }
      console.log(this.defaulatDate, this.endStationCode, this.startStationCode, '---------------')
      this.$router.push({
        path: 'queryResults',
        query: {
          transportation: this.activeIndex,
          start: this.startStation,
          end: this.endStation,
          startCity: this.startCity,
          endCity: this.endCity,
          type: this.$route.query.type, // 来程返程
          bookingMethod: this.$route.query.bookingMethod,
          ticketStatus: this.$route.query.ticketStatus,
          ticketId: this.$route.query.ticketId,
          endStationCode: this.endStationCode,
          startStationCode: this.startStationCode
        }
      })
      //   this.$store.commit('setkeepAlive', ['booking'])
      sessionStorage.removeItem('currentDate')
      sessionStorage.setItem('currentDate', this.defaulatDate)
    },
  }
}
</script>

<style lang="scss" scoped>
.booking {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f3f5f7;
  .booking-header {
    width: 100%;
    height: 6.56rem;
    background: linear-gradient(180deg, #e1ecff 0%, #f2f5f7 100%);
  }
  .content {
    width: 14.73rem;
    height: 10.44rem;
    margin: 0 auto;
    margin-top: -4.14rem;
    background: #ffffff;
    border-radius: 0.49rem;
  }
  .booking-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f3f5f7;
    border-radius: 0.49rem 0.49rem 0rem 0rem;
    .tab-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 1.85rem;
      border-radius: 0.49rem 0.49rem 0rem 0rem;
      > img {
        width: 0.82rem;
        height: 0.82rem;
      }
      > span {
        font-size: 0.66rem;
        color: #162b40;
      }
    }
    .active {
      background: #fff;
      > span {
        font-size: 0.66rem;
        color: #0072f5;
      }
    }
  }

  .submit {
    cursor: pointer;
    width: 13.09rem;
    height: 2.05rem;
    line-height: 2.05rem;
    margin: 0.57rem auto;
    text-align: center;
    background: #0072f5;
    box-shadow: 0rem 0.25rem 0.49rem 0rem rgba(0, 114, 245, 0.15);
    border-radius: 0.33rem;
    > span {
      font-size: 0.74rem;
      color: #ffffff;
    }
  }
}
</style>