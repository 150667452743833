<template>
  <div class="addTicket">
    <div class="addTicket-header">
      <Navbar :title="title" :bgColor="'transparent'"></Navbar>
    </div>
    <div class="content">
      <div class="addTicket-tab">
        <div
          :class="['tab-item', activeIndex == 1 ? ' active' : '']"
          @click="handleTab(1)"
        >
          <img src="@assets/train.png" alt="" />
          <span>火车票</span>
        </div>
        <div
          :class="['tab-item', activeIndex == 2 ? ' active' : '']"
          @click="handleTab(2)"
        >
          <img src="@assets/aircraft.png" alt="" />
          <span>飞机票</span>
        </div>
      </div>
      <address-time
        v-if="activeIndex == 1"
        @start="
          isAddress = true;
          isSetOut = 1;
        "
        @end="
          isAddress = true;
          isSetOut = 2;
        "
        @changeAddress="changeAddress"
        @date="isCalendar = true"
        :start="startStation"
        :end="endStation"
        :date="date"
        :week="week"
      ></address-time>
      <address-time
        v-if="activeIndex == 2"
        @start="
          isAddress = true;
          isSetOut = 1;
        "
        @end="
          isAddress = true;
          isSetOut = 2;
        "
        @changeAddress="changeAddress"
        @date="isCalendar = true"
        :start="startStation"
        :end="endStation"
        :date="date"
        :week="week"
      ></address-time>
    </div>

    <div class="add-ticket-list">
      <div class="mark">
        <img src="@assets/mark.png" alt="" />
      </div>
      <div style="overflow: auto; height: 100%; width: 100%">
        <template v-if="list.length">
          <ticket-list-item
            v-for="(item, index) in list"
            :key="index"
            :index="index"
            :item="item"
            :type="activeIndex"
            @handleClick="handleClick"
            :isSelected="index == selectIndex"
          ></ticket-list-item>
        </template>
        <no-data
          v-if="
            !list.length && this.startStation && this.endStation && this.date
          "
          :icon="require('@assets/no_search.png')"
          :title="'当前线路无直达车次'"
        ></no-data>
      </div>
    </div>
    <div class="submit" @click="submit" v-if="selectIndex != -1">
      <span>确认添加</span>
    </div>
    <!-- 日历 -->
    <van-calendar
      v-model="isCalendar"
      color="#1989fa"
      :show-mark="false"
      :show-confirm="false"
      :min-date="minDate"
      :max-date="maxDate"
      @select="calendarConfirm"
      position="bottom"
      :style="{ width: contentWidth, left: contentLeft }"
    />
    <!-- 选择地区 -->
    <select-address
      v-if="isAddress"
      :showPicker="isAddress"
      :transportation="activeIndex"
      :is-set-out="isSetOut"
      @close="isAddress = false"
      @comfire="comfire"
    ></select-address>
  </div>
</template>

<script>
import Navbar from '@components/Common/Navbar'
import AddressTime from '@components/Common/AddressTime'
import SelectAddress from '@components/Common/SelectAddress'
import TicketListItem from './components/TicketListItem.vue'
import { mapGetters, mapState } from 'vuex'
import noData from '@components/Common/NoData.vue'
import utils from '@utils/index.js'
export default {
  name: 'addTicket',
  components: {
    Navbar,
    SelectAddress,
    AddressTime,
    TicketListItem,
    noData
  },
  data() {
    return {
      title: '添加来程火车票',
      activeIndex: 1, // 交通方式type
      selectIndex: -1,
      isSetOut: 1,
      isCalendar: false,
      isAddress: false,
      date: '',
      week: '',
      startStation: '',
      endStation: '',
      startCity: '',
      endCity: '',
      list: [],
      defaultDate: '',
      selectItem: {},
      minDate: '',
      maxDate: '',
      isClick: false
    }
  },
  watch: {

  },
  created() {
    this.minDate = new Date(sessionStorage.getItem('minDate'))
    this.maxDate = new Date(sessionStorage.getItem('maxDate'))
  },
  mounted() {
    // utils.dealFormData(date, 'Y-m-d'),
    this.date = this.getDay(new Date(this.minDate))
    this.defaultDate = new Date(this.minDate)
    this.week = this.getWeek(new Date(this.minDate))
    this.title = this.$route.query.title
    //  10-在线预定 20-确认出票 25-个人添加 30-申请改签 40-确认改签
    this.$hideLoading()
  },
  computed: {
    ...mapGetters(['contentWidth', 'contentLeft']),
    ...mapState('user', ['guestId'])
  },
  methods: {
    handleTab(index) {
      this.activeIndex = index
      this.selectIndex = -1
      if (this.startStation && this.endStation && this.date) this.getData()
    },
    getData() {
      this.$showLoading()
      this.list = []
      let url = this.activeIndex == 1 ? 'common/getTrain' : 'common/getAirplane'
      let params = {
        startStation: this.startStation,
        endStation: this.endStation,
        startStationCode: this.startStationCode,
        endStationCode: this.endStationCode,
        dateTime: utils.dealFormData(this.defaultDate, 'Y-m-d'),
        type: this.$route.query.type,
        guestId: this.guestId
      }
      this.$Req(url, params).then(res => {
        console.log(res, '查询列表')
        this.list = res
        this.$hideLoading()
      })
    },
    // 转化地址
    changeAddress() {
      const { startStation, endStation, startCity, endCity } = this
      this.startStation = endStation
      this.endStation = startStation
      this.startCity = endCity
      this.endCity = startCity
      if (this.startStation && this.endStation && this.date) this.getData()
    },
    // 获取日期
    getDay(date) {
      console.log(date)
      return (date.getMonth() + 1) + "月" + date.getDate() + "日"
    },
    // 获取周
    getWeek(date) {
      let weeks = ['日', '一', '二', '三', '四', '五', '六']
      let day = new Date(date || new Date()).getDay()
      return `  星期${weeks[day]}出发`
    },
    calendarConfirm(date) {
      this.isCalendar = false
      this.date = this.getDay(date)
      this.week = this.getWeek(date)
      this.defaultDate = date
      if (this.startStation && this.endStation && this.date) this.getData()
    },
    // 地址选择
    comfire(data) {
      if (data.isSetOut == 1) {
        this.startStation = data.stationName
        this.startCity = data.cityName
      } else {
        this.endStation = data.stationName
        this.endCity = data.cityName
      }

      if (this.startStation && this.endStation && this.date) this.getData()
    },
    handleClick(data) {
      this.selectIndex = data.index
      this.selectItem = data.item
    },
    // 查询
    submit() {
      if (this.isClick) return
      this.isClick = true
      if (!this.startStation) {
        this.$toast('请选择出发地')
        return
      } else if (!this.endStation) {
        this.$toast('请选择到达地')
        return
      }
      if (!this.date) {
        this.$toast('请选择出发时间')
        return
      }
      let params = {
        ...this.selectItem,
        guestId: this.guestId,
        ticketNo: this.selectItem.trainNo || this.selectItem.flightNo,
        dateTime: utils.dealFormData(this.defaultDate, 'Y-m-d'),
        startTime: this.selectItem.startTime,
        endTime: this.selectItem.endTime,
        startStation: this.selectItem.startStation,
        endStation: this.selectItem.endStation,
        seat: this.seatType || '',
        startCity: this.startCity,
        endCity: this.endCity,
        cardType: this.$route.query.cardType,
        cardNo: this.$route.query.cardNo,
        trafficType: this.activeIndex,
        type: this.$route.query.type,
        day: this.selectItem.day,
        bookingMethod: this.$route.query.bookingMethod, //是否是个人添加
        ticketId: '', //订票id 修改时传
        ticketStatus: 25, //10-在线预定 20-确认出票 25-个人添加 30-申请改签 40-确认改签

      }
      //   console.log(params)
      this.$Req('common/submitTickets', params).then(res => {
        this.isClick = false
        this.$router.push({
          path: '/'
        })
        console.log(res)
      })
      //   this.$router.push({
      //     path: 'queryResults',
      //     query: {
      //       transportation: this.activeIndex,
      //       ...this.activeIndex == 1 ? this.train : this.aircraft,
      //       date: this.date
      //     }
      //   })
    },
  }
}
</script>

<style lang="scss" scoped>
.addTicket {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f3f5f7;
  .addTicket-header {
    width: 100%;
    height: 6.56rem;
    background: linear-gradient(180deg, #e1ecff, #f2f5f7);
  }
  .content {
    width: 14.73rem;
    margin: 0 auto;
    margin-top: -4.14rem;
    background: #fff;
    border-radius: 0.49rem 0.49rem 0 0;
  }
}
.mark {
  width: 14.73rem;
  height: 0.4rem;
  margin: 0 auto;
  > img {
    width: 100%;
  }
}
::v-deep {
  .time::after {
    height: 0;
  }
  .addTicket-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f3f5f7;
    border-radius: 0.49rem 0.49rem 0rem 0rem;
    .tab-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 1.85rem;
      border-radius: 0.49rem 0.49rem 0rem 0rem;
      > img {
        width: 0.82rem;
        height: 0.82rem;
      }
      > span {
        font-size: 0.66rem;
        color: #162b40;
      }
    }
    .active {
      background: #fff;
      > span {
        font-size: 0.66rem;
        color: #0072f5;
      }
    }
  }
  .add-ticket-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: 0 0.33rem 0.33rem 0.33rem;
  }
}
.submit {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.34rem;
  background: #ffffff;
  padding: 0 0.62rem;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1.64rem;
    background: #0072f5;
    box-shadow: 0rem 0.25rem 0.49rem 0rem rgba(0, 114, 245, 0.15);
    border-radius: 0.33rem;
    font-size: 0.57rem;
    color: #ffffff;
  }
}
</style>