import Req from '@request'

function loginBase({
    dispatch,
    commit,
    params,
    state,
    rootState,
    url
}) {
    return new Promise(async (resolve, reject) => {
        Req.loadRequest(url, params).then(res => {
            commit('setToken', res.token)
            commit('setGuestId', res.id)
            localStorage.setItem('projectId', res.projectId)

            resolve(res)
        }).catch((res) => {
            reject(res)
        })
    })
}

export default {
    loginCode({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return loginBase({
            dispatch,
            commit,
            params,
            state,
            rootState,
            url: 'login/loginCode',
        })
    },
    logout({
        dispatch,
        commit,
        state,
        rootState
    }, params = { intercept: false }) {
        return new Promise((resolve, reject) => {
            commit('setUserInfo', {})
            commit('setToken', '')
            commit('setGuestId', '')
            localStorage.clear()
            sessionStorage.clear()
            localStorage.removeItem('projectCode')
            resolve()
        })
    },

}