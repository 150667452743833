<template>
  <div class="check-in-hotel">
    <div style="height: 0.41rem"></div>
    <Headbar
      :backgroundColor="'linear-gradient(90deg, #9C79FF 0%, #DED3FF 100%)'"
      :text="'入住酒店'"
    ></Headbar>
    <div class="warp" v-if="state == 1">
      <template v-if="!(status == 10 || status == 20)">
        <div class="warp-header">
          <p>
            <img src="@assets/hotel.png" alt="" />
            <span class="van-multi-ellipsis--l2">{{
              item.hotelName || "--"
            }}</span>
          </p>
          <p>
            <span>{{
              dictsCodeMap["guest_hotel_status"][status] == "待安排"
                ? "安排中"
                : dictsCodeMap["guest_hotel_status"][status]
            }}</span>
          </p>
        </div>
        <div class="check-in-time">
          <div class="start">
            <span class="time">{{ item.inTime | formatDate }}</span>
            <span class="address">{{ startWeek || "--" }} 入住</span>
          </div>
          <div class="center">
            <div class="transportation-mode">
              <span></span>
              <span>酒店</span>
              <span></span>
            </div>
            <div class="vote-type">{{ days || "--" }}晚</div>
          </div>
          <div class="end">
            <span class="time">{{ item.outTime | formatDate }}</span>
            <span class="address">{{ endWeek || "--" }} 离店</span>
          </div>
        </div>
        <div class="master-line" v-if="status == 30"></div>
        <div class="master-btn" v-if="status == 30" @click="handleAction">
          <span>无需入住</span>
        </div>
      </template>
      <noData
        v-if="status == 10"
        :icon="require('@assets/self.png')"
        :title="'无需入住'"
        :text="'您已设置为无需入住，如需安排酒店，请联系您的专属秘书进行更改'"
      >
      </noData>
      <!-- hotel_no_data -->
      <template v-if="status == 20">
        <noData
          :icon="require('@assets/hotel_no_data.png')"
          :text="'正在为您安排酒店'"
        ></noData>
        <!-- <div class="master-line"></div> -->
        <div class="master-btn" @click="handleAction">
          <span>无需入住</span>
        </div>
      </template>
    </div>
    <div class="warp" v-else>
      <noData
        :icon="require('@assets/self.png')"
        :title="'无需入住'"
        :text="'您已设置为无需入住，如需安排酒店，请联系您的专属秘书进行更改'"
      >
      </noData>
    </div>
  </div>
</template>

<script>
import Headbar from './Headbar.vue'
import ListItem from '@components/Common/ListItem.vue'
import noData from '@components/Common/NoData.vue'
export default {
  props: {
    item: {},
    state: {},
    status: {},
    dictsCodeMap: {}
  },

  components: {
    Headbar,
    ListItem,
    noData
  },
  filters: {
    formatDate(val) {
      if (!val) return
      return val.split(' ')[0]
    }
  },
  computed: {
    startWeek() {
      return this.getWeek(this.item.inTime)
    },
    endWeek() {
      return this.getWeek(this.item.outTime)
    },
    days() {
      return this.getDaysBetween(this.item.inTime, this.item.outTime)
    }
  },
  methods: {
    getWeek(date) {
      let weeks = ['日', '一', '二', '三', '四', '五', '六']
      let day = new Date(date || new Date()).getDay()
      return `周${weeks[day]}`
    },
    // 计算入住时间
    getDaysBetween(dateString1, dateString2) {
      let startDate = Date.parse(dateString1)
      let endDate = Date.parse(dateString2)
      let days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
      return parseInt(days)
    },
    handleAction() {
      this.$emit('handleAction', this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
.check-in-hotel {
  .warp {
    margin-top: -1rem;
    background: #fff;
    padding: 0rem 0.29rem;
    border-radius: 0.41rem;
    .warp-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 1.64rem;
      p:nth-child(1) {
        display: flex;
        align-items: center;
        > img {
          width: 0.74rem;
          height: 0.74rem;
          margin-right: 0.16rem;
        }
        > span {
          font-size: 0.57rem;
          font-weight: 500;
          color: #162b40;
        }
      }
      p:nth-child(2) {
        display: flex;
        align-items: center;

        > span {
          font-size: 0.57rem;
          color: #0072f5;
        }
      }
    }

    .master-line {
      width: 10.26rem;
      height: 0.02rem;
      margin: 0 auto;
      background: #ececec;
    }
    .master-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 1.64rem;
      justify-content: center;
      > span {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-size: 0.57rem;
        color: rgba(22, 43, 64, 0.45);
      }
    }
    .check-in-time {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.41rem;
      margin-top: 0.21rem;
      .start,
      .end {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .time {
          font-size: 0.66rem;
          font-weight: 600;
          color: #162b40;
        }
        .address {
          margin-top: 0.28rem;
          font-size: 0.49rem;
          color: rgba(22, 43, 64, 0.85);
        }
        .tips {
          position: absolute;
          top: -0.5rem;
          right: 0;
          font-size: 0.45rem;
          color: rgba(22, 43, 64, 0.85);
        }
      }
      .start {
        align-items: flex-start;
      }
      .end {
        align-items: flex-end;
      }
      .center {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .transportation-mode {
          display: flex;
          justify-content: center;
          align-items: center;
          > span {
            display: flex;
          }
          span:nth-child(1) {
            width: 0.74rem;
            height: 0.04rem;
            background: linear-gradient(
              117deg,
              rgba(0, 114, 245, 0),
              rgba(1, 114, 245, 1)
            );
          }
          span:nth-child(2) {
            align-items: center;
            justify-content: center;
            width: 2.54rem;
            height: 0.94rem;
            background: rgba(0, 114, 245, 0.15);
            border-radius: 0.69rem;
            font-size: 0.49rem;
            font-weight: 500;
            color: #0072f5;
            border: 0.04rem solid #0072f5;
          }
          span:nth-child(3) {
            width: 0.74rem;
            height: 0.04rem;
            background: linear-gradient(
              243deg,
              rgba(0, 114, 245, 0),
              rgba(0, 114, 245, 1)
            );
          }
        }
        .vote-type {
          margin-top: 0.21rem;
          font-size: 0.49rem;
          color: rgba(34, 38, 37, 0.65);
        }
      }
    }
  }
  ::v-deep {
    .no-data {
      background: #fff;
      padding: 0.21rem 0 0 0;
      height: 4.56rem;
      overflow: hidden;
      border-radius: 0.41rem;
      > img {
        position: absolute;
        top: 0.21rem;
        left: 0;
        width: 100%;
        height: 4.56rem;
      }
      > span {
        color: #162b40;
      }
    }
  }
}
</style>