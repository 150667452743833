export default [
    'GET projects@/api/Projects',// 获取项目信息
    'GET guests@/api/Projects/{projectId}/Guests/{guestId}',
    'GET getTrain@/api/Projects/{projectId}/Tickets/Train', //火车票查询
    'GET getAirplane@/api/Projects/{projectId}/Tickets/Airplane', //机票查询
    'submitTickets@/api/Projects/{projectId}/Tickets/Submit', //订票提交
    'DELETE dealTickets@/api/Projects/{projectId}/Tickets/{ticketId}', //删除订票、取消出票、申请退票、取消改签、取消退票
    'GET ticketsRecord@/api/Projects/{projectId}/Tickets/Record', //订票记录
    'GET areaCodes@/api/Projects/AreaCodes', //查询地区数据
    'GET getDictStationList@/api/Projects/{projectId}/getDictStationList', // 站点数据查询
    'GET getDicts@/api/Projects/{projectId}/Dict', //字典查询
    'GET AreaCodes@/api/Projects/AreaCodes',
    'PUT edit@/api/Projects/{projectId}/Guests/{guestId}',
    'PUT needCabs@/api/Projects/{projectId}/Cabs/{guestId}',
    'PUT editHotelSubmit@/api/Projects/{projectId}/Hotels/{guestId}/EditSubmit',
    'GET wxConfig@/api/wx/config',
    'GET schedules@/api/Projects/{projectId}/Guests/{guestId}/Schedules',
    'sendSms@/api/Projects/{projectId}/Tickets/sendSms'
    // function getDicts(req, dictName, config) {
    //     return req.post('/api/Projects/{projectId}/Dict', {
    //         dictName
    //     }, config)
    // }
]