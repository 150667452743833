import Req from '@request'
import utils from '@utils'
import wxShare from '@utils/wechat'
import host from '@request/host'
export default {
    getprojects({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        Req.loadRequest('common/projects',
            params
        ).then(res => {
            if (!res.thirdPartyApiCode) res.thirdPartyApiCode = []
            commit('setProject', res)
            sessionStorage.setItem('minDate', res.ticketStartTime)
            sessionStorage.setItem('maxDate', utils.dealFormData(res.ticketEndTime, 'Y-m-d') + ' 23:59:59')
        }).catch(_ => {
        })

    },
    getWx({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise(async (resolve, reject) => {
            Req.loadRequest('common/wxConfig',
                params
            ).then(res => {
                commit('setWxConfig', res)
                let wechatUtils = new wxShare()
                // res.jsApiList[1] = 'onMenuShareAppMessage'
                let tcode = localStorage.getItem('tcode') || ''
                wechatUtils.config(res)
                setTimeout(() => {
                    wechatUtils.share({
                        title: state.project.projectName,
                        desc: `${rootState.user.userInfo.name}\r\n${rootState.user.userInfo.company}`,
                        imgUrl: 'http://file.docbook.com.cn/upload/images/21/d4/de/21d4de874f2245214cce956b78813111.png',
                        link: `https://${host.env ? host.env + '-' : host.env}my.huibangshou.cn?projectCode=${localStorage.getItem('projectCode')}&tcode=${tcode}`
                    })
                }, 1000)
                resolve(res)
            }).catch(_ => {
            })
        })
    },
    getDicts({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise(async (resolve, reject) => {
            Req.loadRequest('#common/getDicts',
                params
            ).then(res => {
                const map = {}
                const dictsCodeMap = {}
                res.forEach(item => {
                    const { dictName, code, codeDesc } = item
                    if (!map[dictName]) map[dictName] = []
                    if (!dictsCodeMap[dictName]) dictsCodeMap[dictName] = {}
                    map[dictName].push(item)
                    dictsCodeMap[dictName][code] = codeDesc
                })
                commit('setDictsCodeMap', dictsCodeMap)
                resolve(dictsCodeMap)
            }).catch(_ => {
            })
        })
    }
};
