import GATRequest from './GATRequest'
import {
    RequestAdapterDownload,
    RequestAdapterOSSUpload,
} from './GATRequest/adapterPlugin'
import host from './host'
import router from '@router'
// import {
//     Message
// } from 'element-ui'

const req = new GATRequest({
    axios: {
        timeout: 60000,
        // baseURL: env.host
    },
    parser: {
        moduleFiles: require.context('./api/modules', true, /\.js$/),
    },
    adapters: [
        RequestAdapterOSSUpload,
        RequestAdapterDownload,
    ],
})

req.onRequest((config) => {

    let {
        url,
        method
    } = config

    const args = { ...(method === 'get' ? config.params : config.data) } || {}

    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token') || ''

    args['projectId'] = localStorage.getItem('projectId') || 1;
    // ...other args
    (url.match(/\{[^{}\s]*\}/g) || []).forEach(argKey => {
        const key = argKey.replace(/\{|\}/g, '') || ''
        const val = args[key]
        if (key && val) {
            url = url.replace(argKey, args[key])
            delete args[key]
        }
    })

    config[method === 'get' ? 'params' : 'data'] = args

    const params = {
        ...config,
        url: /^http/.test(url) ? url : host.domain + url,
    }

    return params
})

req.onResponse((response, makeError) => {
    const {
        data: res,
        config: {
            customConfig = {}
        },
    } = response
    const {
        ignore
    } = customConfig
    if (res.code !== 0 && !ignore) return makeError()
    else if (res.code !== 0 && ignore) return Promise.reject(new Error(res.codeDesc || 'Error'))
    return response.config.responseType === 'blob' ? response : res.data
})

req.onError((error) => {
    if (
        error.response
    ) {
        const {
            data
        } = error.response

        // if (error.code != 4) {
        vant.Toast({
            message: data.codeDesc,
            type: 'error',
        })
        console.log(data.code, 'code')

        if (data.code == 400 || data.code == 403) {
            localStorage.clear()
            router.push('/login')
        }
        // }
    }

    return Promise.reject(new Error(error.message || 'Error'))
})

export default req