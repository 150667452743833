let domain = 'https://ser.huibangshou.cn'
// https://ser.huibangshou.cn
// let socket = "wss://testsocket.docbook.com.cn:8443";
let env = ''
if (
    /test-my/.test(window.location.href)
) {
    domain = 'https://test.huibangshou.cn'
    env = 'test'
}
else if (/localhost/.test(window.location.href)) {
    domain = 'https://test.huibangshou.cn'
    env = 'test'
} else {
    domain = 'https://ser.huibangshou.cn'
}
export default {
    domain,
    env,
}