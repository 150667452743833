export default {
    showLoading(state) {
        state.showLoading = true;
    },
    hideLoading(state) {
        state.showLoading = false;
    },
    setContentLeft(state, contentLeft) {
        state.contentLeft = contentLeft;
    },
    setContentRight(state, contentRight) {
        state.contentRight = contentRight;
    },
    setContentHeight(state, contentHeight) {
        state.contentHeight = contentHeight;
    },
    setTabbarIndex(state, index) {
        state.tabbarIndex = index
    },
    setProjectId(state, id) {
        localStorage.setItem('projectId', id)
        state.projectId = id
    },
    setProject(state, project) {
        sessionStorage.setItem('project', JSON.stringify(project))
        state.project = project
    },
    setkeepAlive(state, aliveList) {
        state.aliveList = aliveList
    },
    setWxConfig(state, config) {
        state.wxConfig = config
    },
    setDictsCodeMap(state, dictsCodeMap) {
        state.dictsCodeMap = dictsCodeMap
    },
};