import {
    RequestAdapter
} from '../src'
import GATOSSUploader from './GATOSSUploader'

export class RequestAdapterOSSUpload extends RequestAdapter {
    constructor(req, loadReq) {
        super(req, loadReq)

        this.tag = 'OSSUPLOAD'
        this.ossUploader = {}
    }

    async handleRequest({
        name,
        url,
        args,
        config,
    }) {
        const {
            file,
            progress,
            isVideoFile = /\.(avi|wmv|mpeg|mp4|mov|mkv|flv|f4v|m4v|rmvb|rm|3gp|dat|ts|mts|vob)$/.test(file.name),
        } = args
        
        const uploaderKey =  isVideoFile ? 'video' : 'normal'
        if (!this.ossUploader[uploaderKey]) { // 懒加载
            const res = await this.req.getAxios().get('https://mapi.docbook.com.cn/api/v2/sts/uploadconfig', {
                params: {
                    type: isVideoFile ? 1 : 2
                }
            })
            this.ossUploader[uploaderKey] = new GATOSSUploader(res.data.data)
        }

        const [maxSize, accept] = url.split('|')
        const reg = /:(\S+)$/

        return this.ossUploader[uploaderKey].upload({
            file,
            maxSize: maxSize.match(reg)[1],
            accept: accept.match(reg)[1]
        }, progress)
    }
}

export class RequestAdapterDownload extends RequestAdapter {
    constructor(req, loadReq) {
        super(req, loadReq)

        this.tag = 'DOWNLOAD'
    }

    handleRequest(info) {

        const {
            url,
            args,
            config,
            method = 'post'
        } = info

        const requestParams = {
            url,
            method,
            customConfig: {
                ...config,
                ignore: true
            },
            data: args,
            responseType: 'blob'
        }

        if (method === 'get') {
            requestParams['params'] = args
            delete requestParams['data']
        }
        
        return new Promise((resolve, reject) => {
            (this.req.getService())(requestParams).then(res => {
                const {
                    data,
                    headers
                } = res
                const blob = new Blob([data], {
                    type: `text/plain;charset=utf-8`
                })
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = headers['content-disposition'] ? headers['content-disposition'].match(/filename="(\S+)"/)[1] : '导出文件.xls'
                link.click()
                resolve(res)
            }).catch(e => {
                reject(e)
            })
        })
    }
}