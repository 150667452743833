<template>
  <div class="query_results">
    <Navbar :bgColor="'#fff'">
      <div class="title" slot="title">
        <span>{{ $route.query.startCity }}</span>
        <img src="@assets/right_arrow.png" />
        <span>{{ $route.query.endCity }}</span>
      </div>
    </Navbar>
    <!-- 日期列表 -->
    <div class="calendar-list">
      <div class="calendar-date" ref="scrollLeft">
        <div
          :class="['list-item', selectDate == index ? 'active' : '']"
          v-for="(item, index) in calendarList"
          :key="index"
          @click="handleDate(index)"
          ref="scroll"
        >
          <span class="week">{{ item.week }}</span>
          <span class="date">{{ item.date }}</span>
        </div>
      </div>
      <div class="calendar-btn" @click="isCalendar = true">
        <img class="calendar" src="@assets/calendar.png" alt="" />
        <img class="down-icon" src="@assets/down_icon.png" alt="" />
      </div>
    </div>
    <!-- 车次列表 -->
    <div class="train-number-list" v-if="transportation == 1">
      <template v-if="train.length">
        <train-number-item
          v-for="item in train"
          :key="item"
          :data="item"
          @handleClick="submitOrder"
        ></train-number-item>
      </template>
      <no-data
        v-else
        :icon="require('@assets/no_search.png')"
        :title="'当前线路无直达车次'"
      ></no-data>
    </div>
    <!-- 飞机列表 -->
    <div class="train-number-list" v-if="transportation == 2">
      <template v-if="air.length">
        <aircraf-number-item
          v-for="item in air"
          :key="item"
          :data="item"
          @handleClick="submitOrder"
        ></aircraf-number-item>
      </template>
      <no-data
        v-else
        :icon="require('@assets/no_search.png')"
        :title="'当前线路无直达车次'"
      ></no-data>
    </div>
    <!-- 日历 -->
    <van-calendar
      v-model="isCalendar"
      :default-date="defaultDate"
      color="#1989fa"
      :show-mark="false"
      :show-confirm="false"
      :min-date="minDate"
      :max-date="maxDate"
      @select="calendarConfirm"
      :style="{ width: contentWidth, left: contentLeft }"
      position="bottom"
    />
  </div>
</template>

<script>
import Navbar from '@components/Common/Navbar'
import TrainNumberItem from './components/TrainNumberItem.vue'
import AircrafNumberItem from './components/AircrafNumberItem.vue'
import utils from '@utils/index.js'
import { mapState, mapGetters } from 'vuex'
import noData from '@components/Common/NoData.vue'
export default {
  components: {
    Navbar,
    TrainNumberItem,
    AircrafNumberItem,
    noData
  },
  data() {
    return {
      title: '',
      selectDate: 0,
      isCalendar: false,
      transportation: 1,
      date: '',
      week: '',
      defaultDate: '',
      calendarList: [],
      train: [],
      air: [],
      minDate: '',
      maxDate: ''
    }
  },
  computed: {
    ...mapState('user', ['guestId']),
    ...mapGetters(['contentWidth', 'contentLeft'])
  },
  created() {
    this.minDate = new Date(sessionStorage.getItem('minDate'))
    this.maxDate = new Date(sessionStorage.getItem('maxDate'))
  },
  mounted() {
    console.log('sadfs', sessionStorage.getItem('minDate'), sessionStorage.getItem('maxDate'))
    utils
      .getMiddleData(
        'day',
        sessionStorage.getItem('minDate'),
        sessionStorage.getItem('maxDate')
      )
      .map(item => {
        this.calendarList.push({
          date: `${item.split('-')[1]}/${item.split('-')[2]}`,
          defaultDate: new Date(item.replace(',', '-')),
          week: this.getWeek(item),
          originDate: item
        })
      })
    const { transportation } = this.$route.query
    this.transportation = transportation
    const date = sessionStorage.getItem('currentDate')
    const index = this.checkDay(date)
    this.selectDate = index
    this.defaultDate = this.calendarList[index || 0].defaultDate

    // 选中日期滑动到可视区
    this.scrollLeft()
    this.getData(date)
  },
  methods: {
    getData(date) {
      this.$showLoading()
      console.log(date, 'date')
      const { transportation, start, startCity, end, endCity, type, endStationCode, startStationCode } = this.$route.query
      let url = transportation == 1 ? 'common/getTrain' : 'common/getAirplane'
      let params = {
        startStation: transportation == 1 ? start : startCity,
        endStation: transportation == 1 ? end : endCity,
        dateTime: utils.dealFormData(date, 'Y-m-d'),
        type: type,
        endStationCode: endStationCode,
        startStationCode: startStationCode,
        guestId: this.guestId
      }
      this.$Req(url, params)
        .then(res => {
          console.log(res, '查询列表')
          this.$hideLoading()
          if (transportation == 1) this.train = res
          else this.air = res
        })
        .catch(err => {
          this.$hideLoading()
          console.log(err)
        })
    },
    // 日期列表选中
    handleDate(index) {
      this.selectDate = index
      this.defaultDate = this.calendarList[index].defaultDate
      sessionStorage.setItem('currentDate', this.defaultDate)
      this.getData(this.defaultDate)
    },
    // 获取周
    getWeek(date) {
      let weeks = ['日', '一', '二', '三', '四', '五', '六']
      let day = new Date(date || new Date()).getDay()
      return `周${weeks[day]}`
    },
    // 获取日期
    getDay(date) {
      return date.getMonth() + 1 + '月' + date.getDate() + '日'
    },
    // 日历组件去确认
    calendarConfirm(date) {
      this.isCalendar = false
      this.date = this.getDay(date)
      this.selectDate = this.checkDay(date)
      this.scrollLeft()
      this.getData(date)
      sessionStorage.setItem('currentDate', date)
    },
    // 判断同一天日期
    checkDay(date) {
      let acctiveIndex = 0
      this.calendarList.map((item, index) => {
        if (
          new Date(item.originDate).setHours(0, 0, 0, 0) ==
          new Date(date).setHours(0, 0, 0, 0)
        ) {
          return (acctiveIndex = index)
        }
      })
      return acctiveIndex
    },
    // 日历滑动
    scrollLeft() {
      let _this = this
      _this.$nextTick(() => {
        const left = document.querySelector('.active').getBoundingClientRect().left - 5
        _this.$refs.scrollLeft.scrollBy({
          left: left, // 每次点击滚动的距离
          behavior: 'smooth'
        })
      })
    },
    submitOrder(data) {
      this.$router.push({
        path: '/details',
        query: {
          week: this.calendarList[this.selectDate].week,
          transportation: this.transportation,
          endCity: this.$route.query.endCity,
          startCity: this.$route.query.startCity,
          startStation: data.startStation,
          endStation: data.endStation,
          startTime: data.startTime,
          endTime: data.endTime,
          trainNo: data.trainNo,
          craft: data.craft,
          flightNo: data.flightNo,
          endTerminal: data.endTerminal,
          punctualRate: data.punctualRate,
          startTerminal: data.startTerminal,
          costTime: data.costTime,
          day: data.day,
          costTime: data.costTime,
          flightNo: data.flightNo,
          airline: data.airline,
          craft: data.craft,
          food: data.food,
          type: this.$route.query.type,
          ticketId: this.$route.query.ticketId,
          ticketStatus: this.$route.query.ticketStatus,
          bookingMethod: 1
        }
      })
      sessionStorage.removeItem('seatList')
      sessionStorage.setItem('seatList', JSON.stringify(data.seatList))
    }
  }
}
</script>

<style lang="scss" scoped>
.query_results {
  display: flex;
  flex-direction: column;

  .title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      font-size: 0.66rem;
    }
    > img {
      margin: 0 0.16rem;
      width: 0.66rem;
      height: 0.66rem;
    }
  }
  .calendar-list {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 2.79rem;
    padding: 0 0.21rem;
    background: #fff;
    .calendar-date {
      display: flex;
      width: 100%;
      height: 2.79rem;
      padding-right: 3rem;
      overflow-y: hidden;
      overflow-x: auto;
      .list-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 1.97rem;
        height: 2.79rem;
        > .week {
          font-size: 0.57rem;
          color: #162b40;
        }
        > .date {
          margin-top: 0.1rem;
          font-size: 0.49rem;
          color: rgba(22, 43, 64, 0.45);
        }
      }
      .active {
        background: linear-gradient(180deg, #bedcff 0%, #f3f5f7 100%);
        border-radius: 0.33rem 0.33rem 0rem 0rem;
        > .week {
          font-size: 0.57rem;
          font-weight: 500;
          color: #0072f5;
        }
        > .date {
          margin-top: 0.1rem;
          font-size: 0.49rem;
          color: #0072f5;
        }
      }
    }
    .calendar-btn {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 2.79rem;
      padding-left: 0.5rem;
      z-index: 10;
      background: url("~@assets/left_mark.png") no-repeat center/cover;
      background-position-x: 0;
      background-position-y: 0;
      background-size: 0.62rem 100%;
      background-color: #fff;
      > .calendar {
        width: 0.98rem;
        height: 0.98rem;
      }
      > .down-icon {
        width: 0.66rem;
        height: 0.66rem;
      }
    }
  }
  .train-number-list {
    flex: 1;
    padding-bottom: 0.25rem;
    overflow: auto;
    ::v-deep {
      > span {
        color: rgba(22, 43, 64, 0.65) !important;
      }
    }
  }
}
</style>