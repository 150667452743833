<template>
  <div
    class="loading"
    :style="{ width: contentWidth, zIndex: 2002, left: contentLeft }"
  >
    <img src="@assets/loading.gif" alt="" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['contentWidth', 'contentLeft']),

  },
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(239, 242, 249, 1);
  z-index: 9999;
  img {
    width: 8.16rem;
    height: 8.16rem;
  }
}
</style>