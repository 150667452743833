var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
      'sidebar-item',
      index == _vm.active ? 'active' : '',
      item.index == 0 ? 'schedule-icon' : '',
    ],on:{"click":function($event){return _vm.handleClick(item, index)}}},[(item)?[(item.label == '我的日程')?[_c('img',{attrs:{"src":require("@assets/schedule_icon.png"),"alt":""}}),_c('span',[_vm._v("我的日程")])]:[_c('div',{staticClass:"sidebar-item-title"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"sidebar-item-information van-multi-ellipsis--l2"},[_vm._v(" "+_vm._s(item.value)+" ")])]]:_vm._e(),(
        (_vm.active != index && _vm.active > index + 1) ||
        (_vm.active != index && _vm.active < index)
      )?_c('img',{staticClass:"bottom-right",attrs:{"src":require("@assets/horn.png"),"alt":""}}):_vm._e(),(_vm.active != index && _vm.active + 1 != index)?_c('img',{staticClass:"top-right",attrs:{"src":require("@assets/horn.png"),"alt":""}}):_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }