<template>
  <div class="reception">
    <div class="reception-cover">
      <img
        v-if="url"
        class="back"
        src="@assets/home_back.png"
        @click="goBack"
        alt=""
      />
      <img
        :src="project.backgroundImg || require('@assets/default_bg.png')"
        alt=""
      />
    </div>
    <div class="reception-personal">
      <div class="personal-information">
        <div class="head-sculpture">
          <img
            :src="data.profilePic || require('@assets/default_photo.png')"
            alt=""
          />
        </div>
        <div
          class="job-details"
          style="flex-direction: column; align-items: start"
        >
          <div class="name">
            <span>{{ data.name }}</span>
            <span v-if="data.job">{{ data.job }}</span>
          </div>
          <div class="work-address">
            <span>{{ data.company || "-" }}</span>
          </div>
        </div>
      </div>
      <!-- 专属秘书 -->
      <div
        class="exclusive-secretary"
        style="margin-left: 0"
        v-if="data.manager"
      >
        <div class="job-details" style="margin-left: 0">
          <div class="name">
            <span style="font-size: 0.57rem">专属秘书:</span>
            <span style="font-size: 0.57rem">{{ data.manager.name }}</span>
          </div>
        </div>
        <div
          class="phone"
          v-if="data.manager.phone"
          @click="callPhone(data.manager.phone)"
        >
          <img src="@assets/phone.png" alt="" />
        </div>
      </div>
      <!-- 任务书 -->
      <div
        class="assignment"
        v-if="
          project.thirdPartyApiCode &&
          project.thirdPartyApiCode.includes('GuestTask') &&
          schedule.url
        "
      >
        <div class="assignment-left">
          <img src="@assets/assignment_logo.png" alt="" />
          <div class="left-des">
            <p>我的任务书</p>
            <p>点击进入任务书，查看个人代班事项</p>
          </div>
        </div>
        <div class="assignment-right">
          <div class="right-btn" @click="goAssignment">
            点击查看
            <span v-if="schedule.count">{{ schedule.count }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-left">
        <sidebar
          @click="handlesidebar"
          :active="activeBtn"
          :label="sideLabel"
        ></sidebar>
      </div>
      <div class="content-right" @scroll="scroll" ref="anchorRef">
        <schedule
          v-if="
            project.thirdPartyApiCode &&
            project.thirdPartyApiCode.includes('GuestSchedule') &&
            schedule.sessions
          "
          class="scroll-item"
          :sessions="schedule.sessions"
          :index="0"
        ></schedule>
        <!-- 来程订票 -->
        <incoming-booking
          :index="1"
          ref="anchor1"
          class="scroll-item"
          :tickets="inComing"
          v-if="
            data.outboundTicketState == 1 && data.outboundTicketStatus != 10
          "
          :dictsCodeMap="dictsCodeMap"
          :state="data.outboundTicketState"
          :status="data.outboundTicketStatus"
          @add="addBooking(1)"
          @addSlefTickes="handleAddVote(1)"
          @onlineTickes="handleOnline(1)"
          @handletTicket="handletTicket"
        ></incoming-booking>
        <!-- 来程接车 -->
        <incoming-pickup
          :index="2"
          class="scroll-item"
          ref="anchor2"
          :car="inComingCar"
          v-if="data.outboundCabState == 1 && data.outboundCabStatus != 10"
          @handleAction="handleAction"
          :dictsCodeMap="dictsCodeMap"
          :state="data.outboundCabState"
          :status="data.outboundCabStatus"
        ></incoming-pickup>
        <!-- 入住酒店 -->
        <check-in-hotel
          :index="3"
          class="scroll-item"
          ref="anchor3"
          :item="hotel"
          v-if="data.hotelState == 1 && data.hotelStatus != 10"
          @handleAction="needAction"
          :dictsCodeMap="dictsCodeMap"
          :status="data.hotelStatus"
          :state="data.hotelState"
        ></check-in-hotel>
        <!-- 返程订票 -->
        <return-booking
          :index="4"
          ref="anchor4"
          class="scroll-item"
          @add="addBooking(2)"
          @addSlefTickes="handleAddVote(2)"
          @onlineTickes="handleOnline(2)"
          :tickets="returnList"
          :dictsCodeMap="dictsCodeMap"
          v-if="data.returnTicketState == 1 && data.returnTicketStatus != 10"
          :state="data.returnTicketState"
          :status="data.returnTicketStatus"
          @handletTicket="handletTicket"
        ></return-booking>
        <!-- 返程接车 -->
        <return-pickup
          :index="5"
          class="scroll-item"
          :car="returnCar"
          @handleAction="handleAction"
          :dictsCodeMap="dictsCodeMap"
          v-if="data.returnCabState == 1 && data.returnCabStatus != 10"
          :status="data.returnCabStatus"
          :state="data.returnCabState"
          ref="anchor5"
        ></return-pickup>

        <div class="srogen" v-if="project.hideLogo != 1">
          <img src="@assets/logo.png" alt="" />
        </div>
        <div style="height: 15rem"></div>
      </div>
    </div>
    <!-- 添加订票 -->
    <van-popup
      v-model="isBooking"
      round
      position="bottom"
      :style="{ width: contentWidth, left: contentLeft }"
    >
      <div class="warp">
        <div class="header">
          <span>添加订票</span>
          <img src="@assets/close.png" @click="isBooking = false" alt="" />
        </div>
        <div class="warp-booking">
          <span class="add" @click="handleAddVote">添加个人已订票</span>
          <span class="booking" @click="handleOnline">在线订票</span>
        </div>
      </div>
    </van-popup>
    <!-- 获取操作人信息 -->
    <OperationDialog
      :show="isOperation"
      :type="channelType"
      @handleOperation="handleOperation"
      @close="isOperation = false"
    ></OperationDialog>
  </div>
</template>

<script>
import OperationDialog from '@components/Common/OperationDialog.vue'
import ListItem from '@components/Common/ListItem.vue'
import Sidebar from '@components/Reception/Sidebar.vue'
import IncomingBooking from '@components/Reception/IncomingBooking.vue'
import IncomingPickup from '@components/Reception/IncomingPickup.vue'
import CheckInHotel from '@components/Reception/CheckInHotel.vue'
import ReturnPickup from '@components/Reception/ReturnPickup.vue'
import ReturnBooking from '@components/Reception/ReturnBooking.vue'
import Schedule from '@components/Reception/Schedule.vue'
import utils from '@utils/index.js'
import { mapState, mapGetters } from 'vuex'
import os from "@utils/os";
export default {
  name: 'Home',
  components: {
    ListItem,
    Sidebar,
    IncomingBooking,
    IncomingPickup,
    CheckInHotel,
    ReturnBooking,
    ReturnPickup,
    Schedule,
    OperationDialog
  },
  data() {
    return {
      isOperation: false,
      ticketId: '',
      activeBtn: 0,
      currentRate: 10,
      isBooking: false,
      sideLabel: [],
      data: {},
      inComing: [], //来程订票
      returnList: [], // 返程订票
      inComingCar: {}, //来程接车
      returnCar: {}, // 返程接车
      hotel: {},
      type: '', // 来程返程 1来 2反
      statusMaping: [],
      seatMaping: [],
      project: {},

      schedule: {
        sessions: []
      },
      url: '',
      dictsCodeMap: {},
    }
  },
  async mounted() {
    this.$showLoading()
    this.$store.commit('setTabbarIndex', 0)
    if (this.$route.query.url) {
      sessionStorage.setItem('back_url', this.$route.query.url)
      this.url = this.$route.query.url
    } else {
      if (sessionStorage.getItem('back_url')) this.url = sessionStorage.getItem('back_url')
    }

    if (this.$route.query.tcode) {
      await this.$store.dispatch('user/loginCode', {
        identityKey: this.$route.query.tcode
      }).then(res => {
        localStorage.setItem('projectCode', this.$route.query.projectCode)
        localStorage.setItem('tcode', this.$route.query.tcode)
      })
    } else {
      if (!localStorage.getItem('token')) {
        this.$router.push('/login')
        return
      }
    }
    this.project = JSON.parse(sessionStorage.getItem('project'))
    await this.getDict()

    await this.getData()

    console.log(this.$refs.anchorRef.getBoundingClientRect().height)
  },
  computed: {
    ...mapState(['projectId']),
    ...mapState('user', ['guestId', 'userInfo']),
    ...mapGetters(['contentWidth', 'contentLeft']),
  },
  methods: {
    // //微信分享
    // getWxShare() {
    //   this.$Req('common/wxConfig', { url: location.href }).then(res => {
    //     console.log(res, '分享获取config')
    //     this.$wx.config(res)
    //     setTimeout(() => {
    //       this.$wx.share({
    //         title: this.project.projectName,
    //         desc: `${this.userInfo.name}\r\n${this.userInfo.company}`,
    //         imgUrl: 'https://' + location.host + '/stats/icon/logo.png',
    //         link: `https://${host.env ? host.env + '-' : host.env}my.huibangshou.cn?projectCode=${localStorage.getItem('projectCode')}&tcode=${localStorage.getItem('tcode')}`
    //       })
    //     }, 10000)

    //   })
    // },
    // 获取专家日程
    getSchedules(data) {
      this.$Req('*common/schedules', {
        guestId: this.guestId
      }).then(res => {
        console.log(res, '-------')
        this.schedule = res
        if (!res) {
          this.sideLabel[0].isShow = false
        } else {

        }
        this.menusActive(data)
        console.log(res, '获取日程列表')
      }).catch(err => {
        this.sideLabel[0].isShow = false
        this.menusActive(data)
      })
    },

    // 返回任务单
    goBack() {
      window.location.href = window.atob(this.url)
    },
    // 跳转到任务书
    goAssignment() {
      if (os.isPC()) {
        window.open(this.schedule.url)
      } else {
        window.location.href = this.schedule.url
      }

    },
    // 获取字典
    getDict() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getDicts').then(res => {
          this.dictsCodeMap = res
          resolve(res)
        })
      })
    },
    // 时间排序
    sortedList(list) {
      if (!list.length) return []
      return list.sort((a, b) => {
        return new Date(`${a.order.startDate} ${a.order.startTime}`).getTime() - new Date(`${b.order.startDate} ${b.order.startTime}`).getTime();
      })
    },
    // 获取详细信息
    getData() {
      return new Promise((resolve, reject) => {
        this.sideLabel = [
          {
            index: 0,
            label: '我的日程',
            value: '',
            isShow: true
          },
          {
            index: 1,
            label: '来程订票',
            value: '',
            isShow: true
          },
          {
            index: 2,
            label: '接站',
            value: '',
            isShow: true
          },
          {
            index: 3,
            label: '入住酒店',
            value: '',
            isShow: true
          },
          {
            index: 4,
            label: '返程订票',
            value: '',
            isShow: true
          },
          {
            index: 5,
            label: '送站',
            value: '',
            isShow: true
          },
          ''
        ]
        this.$Req(`common/guests`, { guestId: this.guestId }).then(res => {
          this.data = res
          sessionStorage.setItem('name', res.name)
          this.$store.commit('user/setUserInfo', {
            phone: res.phone,
            name: res.name,
            cardNo: res.cardNo,
            area: res.area,
            company: res.company,
            cardType: res.cardType,
            department: res.department,
            job: res.job,
            cardNo: res.cardNo,
            appendState: res.appendState
          })
          let returnList = []
          this.returnList = []
          let inComing = []
          this.inComing = []
          if (this.data.tickets) {
            this.data.tickets = this.sortedList(this.data.tickets)
            this.data.tickets.map(item => {
              if (item.type == 1) inComing.push(item)
              else returnList.push(item)
            })
          }
          if (inComing.length) {
            inComing.map(item => {
              if (!(item.status == 70 || item.status == 90 || item.status == 95)) {
                this.inComing.push(item)
              }
            })
          }
          if (returnList.length) {
            returnList.map(item => {
              if (!(item.status == 70 || item.status == 90 || item.status == 95)) this.returnList.push(item)
            })
          }
          if (this.data.cabs) this.data.cabs.map(item => {
            if (item.type == 1) this.inComingCar = item
            else this.returnCar = item
          })
          this.hotel = this.data.hotel
          this.project = JSON.parse(sessionStorage.getItem('project'))
          if (this.project.thirdPartyApiCode.includes('GuestSchedule') || this.project.thirdPartyApiCode.includes('GuestTask')) {
            this.getSchedules(this.data)
          } else {
            this.menusActive(this.data)
          }
          // 左侧tab 相关逻辑
          this.getSilderText(this.data)
          resolve()

          this.$hideLoading()
        })

      }).catch(err => {
        reject()
        this.$hideLoading()
        console.log(err)
      })
    },
    /**
          *  outboundTicketState 来程状态 0不需要 1需要 
          *  outboundTicketStatus 来程票状态 10 无需订票 20 待订票 guest_ticket_status
          *  returnTicketState 返程
          *  returnTicketStatus 返程票状态 10 无需订票 20 代订票 guest_ticket_status
          *  outboundCabState 来程接车
          *  outboundCabStatus 10 无需 30 司机已确定 40 已出发 50 已到达 60 已上车 70 已送达
          *  returnCabState 返程接车
          *  returnCabStatus 10 无需 30 司机已确定 40 已出发 50 已到达 60 已上车 70 已送达
          *  hotelState 入住状态
          *  hotelStatus 10 无需 30 未入住 40 已入住 50 已离店
          * **/
    // 设置右侧菜单文案
    getSilderText() {
      this.$showLoading()
      const { outboundTicketState, outboundTicketStatus, returnTicketState, returnTicketStatus, outboundCabState, outboundCabStatus, returnCabState, returnCabStatus, hotelState, hotelStatus, guestThirdPartyInfo } = this.data
      setTimeout(() => {
        if (!this.project.thirdPartyApiCode.includes('GuestSchedule')) {
          this.sideLabel[0].isShow = false
        }
        if (outboundTicketState == 1) {
          if (outboundTicketStatus == 10 || outboundTicketStatus == 20) {
            this.sideLabel[1].value = this.dictsCodeMap['guest_ticket_status'][outboundTicketStatus]
            if (outboundTicketStatus == 10) this.sideLabel[1].isShow = false
          } else {
            if (this.inComing.length)
              this.sideLabel[1].value = `${this.inComing[0].order.startStation}${this.inComing[0].order.startTerminal || ''}-${this.inComing[this.inComing.length - 1].order.endStation}${this.inComing[this.inComing.length - 1].order.endTerminal || ''} `
          }
        } else {
          this.sideLabel[1].isShow = false
          this.sideLabel[1].value = '无需订票'
        }
        if (outboundCabState == 1) {
          this.sideLabel[2].value = utils.dealDictText(this.dictsCodeMap['guest_cab_status'][outboundCabStatus], 1)
          if (outboundCabStatus == 10) this.sideLabel[2].isShow = false
        } else {
          this.sideLabel[2].isShow = false

        }
        if (hotelState == 1) {
          this.sideLabel[3].value = this.dictsCodeMap['guest_hotel_status'][hotelStatus] == '待安排' ? '安排中' : this.dictsCodeMap['guest_hotel_status'][hotelStatus]
          if (hotelStatus == 10) this.sideLabel[3].isShow = false
        } else {
          this.sideLabel[3].isShow = false
          this.sideLabel[3].value = '无需入住'
        }
        if (returnTicketState == 1) {
          if (returnTicketStatus == 10 || returnTicketStatus == 20) {
            if (returnTicketStatus == 10) this.sideLabel[4].isShow = false
            this.sideLabel[4].value = this.dictsCodeMap['guest_ticket_status'][returnTicketStatus]
          } else {
            if (this.returnList.length)
              this.sideLabel[4].value = `${this.returnList[0].order.startStation}${this.returnList[0].order.startTerminal || ''} - ${this.returnList[this.returnList.length - 1].order.endStation}${this.returnList[this.returnList.length - 1].order.endTerminal || ''}`
          }
        } else {
          this.sideLabel[4].isShow = false
          this.sideLabel[4].value = '无需订票'
        }
        if (returnCabState == 1) {
          this.sideLabel[5].value = utils.dealDictText(this.dictsCodeMap['guest_cab_status'][returnCabStatus], 2)
          if (returnCabStatus == 10) this.sideLabel[5].isShow = false
        } else {
          this.sideLabel[5].isShow = false
          this.sideLabel[5].value = '无需接车'
        }
        this.$hideLoading()
      }, 0)
    },
    // 设置导航默认选中判断
    menusActive(data) {
      const { outboundTicketState, outboundTicketStatus, returnTicketState, returnTicketStatus, outboundCabState, outboundCabStatus, returnCabState, returnCabStatus, hotelState, hotelStatus } = data
      //  来程订票 需要订票 行程最后一个状态不是已出发 选中
      if (outboundTicketState == 1) {
        if (outboundTicketStatus == 20) {
          this.handlesidebar('', 'incoming-booking')
          return
        }
        if (this.inComing.length && this.inComing[this.inComing.length - 1].status != 50) {
          this.handlesidebar('', 'incoming-booking')
          return
        }
      }
      // 需要接车 状态不是已送达 选中
      if (outboundCabState == 1) {
        if (outboundCabStatus != 70 && outboundCabStatus != 10) {
          this.handlesidebar('', 'incoming-pickup')
          return
        }
      }
      // 入住 不是已入住、已离店、需要入住 选中
      if (hotelState == 1) {
        if (hotelStatus != 50 && hotelStatus != 40 && hotelStatus != 10) {
          this.handlesidebar('', 'check-in-hotel')
          return
        }
      }
      //  返程订票 需要订票 行程最后一个状态不是已出发 选中
      if (returnTicketState == 1) {
        if (returnTicketStatus == 20) {
          this.handlesidebar('', 'return-booking')
          return
        }
        if (this.returnList.length && this.returnList[this.returnList.length - 1].status != 50) {
          this.handlesidebar('', 'return-booking')
          return
        }
      }
      // 送车 状态不是已送达 选中
      if (returnCabState == 1) {
        if (returnCabStatus != 70 && returnCabStatus != 10) {
          this.handlesidebar('', 'return-pickup')
          return
        }
      }
      // 都不符合默认选中第一个
      //   this.handlesidebar(this.activeBtn)
    },
    // 打电话
    callPhone(phoneNumber) {
      //   this.$dialog.confirm({
      //     title: '提示',
      //     message: `您确认要拨打电话:${phoneNumber}吗?`,
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     // cancelButtonColor: '#323233',
      //     confirmButtonColor: '#0089FF'
      //   }).then(() => {
      // console.log('点击取消')
      window.location.href = 'tel://' + phoneNumber
      //   })

    },
    // 添加个人订票
    handleAddVote(action) {
      console.log(action, (this.type || action), '-------')
      this.$router.push({
        path: '/addTicket',
        query: {
          title: (this.type || action) == 1 ? '添加来程火车票' : '添加返程火车票',
          type: this.type || action,
          bookingMethod: 2,
          ticketStatus: '25',
          cardType: this.data.cardType,
          cardNo: this.data.cardNo
        }
      })
    },
    // 在线订票
    handleOnline(index) {
      this.$router.push({
        path: '/booking',
        query: {
          ticketStatus: '10',
          bookingMethod: 1,
          type: this.type || index
        }
      })
    },
    // 无需接车
    handleAction(data) {
      this.$dialog.confirm({
        message: `您确认选择无需${data.type == 1 ? '接站' : '送站'}么?`,
        confirmButtonText: '取消',
        cancelButtonText: `无需${data.type == 1 ? '接站' : '送站'}`,
        cancelButtonColor: '#323233',
        confirmButtonColor: '#0089FF'
      }).then(() => {
        console.log('点击取消')
      }).catch(() => {
        this.$Req('common/needCabs', {
          guestId: this.guestId,
          id: data.id,
          cabState: 0,
          cabStatus: 10,
          type: data.type
        }).then(res => {
          this.getData()
        });
      })
    },
    // 无需入住
    needAction(data) {
      console.log(data)
      this.$dialog.confirm({
        message: '您确认选择无需入住么?',
        confirmButtonText: '取消',
        cancelButtonText: '无需入住',
        cancelButtonColor: '#323233',
        confirmButtonColor: '#0089FF'
      }).then(() => {
        console.log('点击取消')
      }).catch(() => {
        this.$Req('common/editHotelSubmit', {
          guestId: this.guestId,
          //   id: data.id,
          hotelStatus: 10,
          hotelState: 0,
        }).then(res => {

          console.log(res)
          this.getData()
        });

      })
    },
    addBooking(index) {
      this.isBooking = true
      this.type = index
    },
    // 使元素滚动到可视区域
    handlesidebar(index, className) {
      // className 用于判断默认选中
      // index 左侧点击
      setTimeout(() => {
        if (className) {
          document.querySelector(`.${className}`).scrollIntoView({ behavior: 'smooth' })
        } else {
          document.querySelectorAll(`.scroll-item`)[index].scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)

    },
    // 处理模块状态
    handletTicket(data) {
      console.log(data)
      if (data.type == 6) {
        this.$router.push({
          path: '/queryResults',
          query: {
            ...data,
            type: data.iscoming,
            ticketId: data.id,
            ticketStatus: '30',
          }
        })
        sessionStorage.removeItem('currentDate')
        sessionStorage.setItem('currentDate', new Date(data.date))
      } else {
        this.ticketId = data.id

        if (data.type == 3) {
          this.isOperation = true
          this.channelType = data.type
          return
        }
        let params = {
          ticketId: data.id,
          actionType: data.type
        }

        this.$Req('common/dealTickets', params).then(res => {
          this.getData()
        })
      }
      console.log(data, '票务状态')
    },
    // 收集操作人信息
    handleOperation(data) {
      console.log(data, '操作人信息')
      let params = {
        ...data,
        ticketId: this.ticketId,
        actionType: '3'
      }
      this.$Req('common/dealTickets', params).then(res => {
        this.getData()
        this.isOperation = false
      })
    },
    debounce(fn, time) {
      let timer = null;
      return function () {
        if (timer) clearTimeout(timer)
        timer = setTimeout(function () {
          fn()
        }, time)
      }
    },
    logic(heights) {    // 获取当前文档流的 scrollTop
      const scrollTop = this.$refs.anchorRef.scrollTop
      //   console.log(heights, '----------')
      heights.forEach((item, index) => {
        if (scrollTop >= item.height) {
          this.activeBtn = item.id >= 5 ? 5 : item.id
        } else if (scrollTop <= heights[0].height) {
          this.activeBtn = 0
        }
      })
    },
    // 滑动
    scroll(e) {
      //   获取所有锚点元素
      const scrolls = document.querySelectorAll('.scroll-item')
      const anchorRefH = this.$refs.anchorRef.offsetTop
      // 所有锚点元素的 offsetTop
      const heights = []
      scrolls.forEach((item, index) => {
        heights.push({
          Eheight: item.getBoundingClientRect().height,
          index: item.getAttribute('index'),
          height: item.offsetTop - anchorRefH + item.getBoundingClientRect().height - 15,
          id: index + 1
        })
      })
      this.debounce(this.logic(heights), 400)
    }
  },
}
</script>
<style lang="scss" scoped>
.reception {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: rgba(239, 242, 249, 1);
  .reception-cover {
    position: relative;
    width: 100%;
    height: 2.87rem;
    background: #fff;
    .back {
      position: absolute;
      left: 0.41rem;
      top: 0.41rem;
      width: 1.11rem;
      height: 1.11rem;
      background: rgba(255, 255, 255, 0.85);
      border-radius: 0.55rem;
    }
    img {
      width: 100%;
      height: 100%;
      //   border-radius: 0.49rem 0.49rem 0rem 0rem;
    }
  }
  .reception-personal {
    background: linear-gradient(180deg, #ffffff 0%, #e1ecfe 100%);
    // border-radius: 0rem 0rem 0.41rem 0rem;
    // border-radius: 0.49rem;
    padding: 0 0.66rem;
  }
  .personal-information {
    display: flex;
    justify-content: space-between;
    width: 100%;
    // border-bottom: 0.02rem solid #d2ddf0;
    padding: 0.41rem 0;
    .head-sculpture {
      width: 2.05rem;
      height: 2.05rem;
      border: 0.02rem solid #ffffff;
      border-radius: 100%;
      > img {
        width: 2.05rem;
        height: 2.05rem;
        border-radius: 100%;
      }
    }
  }
  .exclusive-secretary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.31rem;
    background: rgba(0, 114, 245, 0.1);
    border-radius: 0.16rem;
    padding: 0rem 0.41rem !important;
    margin-bottom: 0.49rem;
  }
  .job-details {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 0.57rem;
    height: 2.2rem;
    .name {
      span:nth-child(1) {
        font-size: 0.74rem;
        font-weight: 500;
        color: rgba(22, 43, 64, 0.85);
      }
      span:nth-child(2) {
        margin-left: 0.41rem;
        font-size: 0.49rem;
        color: rgba(22, 43, 64, 0.65);
      }
    }
    .work-address {
      margin-top: 0.33rem;
      span {
        font-size: 0.49rem;
        color: rgba(22, 43, 64, 0.65);
      }
    }
  }
  .phone {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 1.31rem;
    border-radius: 1.03rem;
    > img {
      width: 0.7rem;
      height: 0.82rem;
    }
  }
  .assignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2.15rem;
    padding: 0 0.49rem;
    background: url("~@assets/assignment_bg.png") no-repeat center/cover;
    .assignment-left {
      display: flex;
      align-items: center;
      > img {
        width: 1.39rem;
        height: 1.39rem;
      }
      .left-des {
        margin-left: 0.41rem;
        p:nth-child(1) {
          font-size: 0.57rem;
          font-weight: 600;
          color: #ffe7db;
        }
        p:nth-child(2) {
          font-size: 0.41rem;
          color: #edd4c7;
        }
      }
    }
    .right-btn {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.79rem;
      height: 1.11rem;
      background: linear-gradient(138deg, #f9d3ae 0%, #fce6c4 100%);
      border-radius: 1.03rem;
      font-size: 0.49rem;
      color: #53320e;
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: -0.16rem;
        width: 0.62rem;
        height: 0.62rem;
        border-radius: 100%;
        background: #f5222d;
        font-size: 0.37rem;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    overflow: auto;
    padding-bottom: 0.82rem;
    .content-left {
      width: 3.41rem;
      height: 100%;
      background: #fff;
      overflow: auto;
    }
    .content-right {
      flex: 1;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 0.25rem 0.41rem;
      .srogen {
        width: 9.44rem;
        height: 1.64rem;
        margin: 0 auto;
        margin-top: 0.82rem;
        margin-bottom: 1.66rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .warp {
    background: #fff;
    border-radius: 0.41rem 0.41rem 0 0;
    height: 6.69rem;
    .header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.66rem;
      height: 1.97rem;
      margin-bottom: 0.41rem;
      span {
        font-size: 0.66rem;
        font-weight: 500;
        color: #323233;
      }
      > img {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
    .warp-booking {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.7rem;
      padding-bottom: 1.23rem;
      cursor: pointer;
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .add {
        width: 6.69rem;
        height: 1.81rem;
        border-radius: 0.21rem;
        border: 0.02rem solid #dcdee0;
        font-size: 0.57rem;
        color: #162b40;
      }
      .booking {
        width: 6.69rem;
        height: 1.81rem;
        background: #0072f5;
        box-shadow: 0rem 0.25rem 0.49rem 0rem rgba(0, 114, 245, 0.15);
        border-radius: 0.21rem;
        font-size: 0.57rem;
        color: #ffffff;
      }
    }
  }
}
</style>