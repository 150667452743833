var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addTicket"},[_c('div',{staticClass:"addTicket-header"},[_c('Navbar',{attrs:{"title":_vm.title,"bgColor":'transparent'}})],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"addTicket-tab"},[_c('div',{class:['tab-item', _vm.activeIndex == 1 ? ' active' : ''],on:{"click":function($event){return _vm.handleTab(1)}}},[_c('img',{attrs:{"src":require("@assets/train.png"),"alt":""}}),_c('span',[_vm._v("火车票")])]),_c('div',{class:['tab-item', _vm.activeIndex == 2 ? ' active' : ''],on:{"click":function($event){return _vm.handleTab(2)}}},[_c('img',{attrs:{"src":require("@assets/aircraft.png"),"alt":""}}),_c('span',[_vm._v("飞机票")])])]),(_vm.activeIndex == 1)?_c('address-time',{attrs:{"start":_vm.startStation,"end":_vm.endStation,"date":_vm.date,"week":_vm.week},on:{"start":function($event){_vm.isAddress = true;
        _vm.isSetOut = 1;},"end":function($event){_vm.isAddress = true;
        _vm.isSetOut = 2;},"changeAddress":_vm.changeAddress,"date":function($event){_vm.isCalendar = true}}}):_vm._e(),(_vm.activeIndex == 2)?_c('address-time',{attrs:{"start":_vm.startStation,"end":_vm.endStation,"date":_vm.date,"week":_vm.week},on:{"start":function($event){_vm.isAddress = true;
        _vm.isSetOut = 1;},"end":function($event){_vm.isAddress = true;
        _vm.isSetOut = 2;},"changeAddress":_vm.changeAddress,"date":function($event){_vm.isCalendar = true}}}):_vm._e()],1),_c('div',{staticClass:"add-ticket-list"},[_vm._m(0),_c('div',{staticStyle:{"overflow":"auto","height":"100%","width":"100%"}},[(_vm.list.length)?_vm._l((_vm.list),function(item,index){return _c('ticket-list-item',{key:index,attrs:{"index":index,"item":item,"type":_vm.activeIndex,"isSelected":index == _vm.selectIndex},on:{"handleClick":_vm.handleClick}})}):_vm._e(),(
          !_vm.list.length && this.startStation && this.endStation && this.date
        )?_c('no-data',{attrs:{"icon":require('@assets/no_search.png'),"title":'当前线路无直达车次'}}):_vm._e()],2)]),(_vm.selectIndex != -1)?_c('div',{staticClass:"submit",on:{"click":_vm.submit}},[_c('span',[_vm._v("确认添加")])]):_vm._e(),_c('van-calendar',{style:({ width: _vm.contentWidth, left: _vm.contentLeft }),attrs:{"color":"#1989fa","show-mark":false,"show-confirm":false,"min-date":_vm.minDate,"max-date":_vm.maxDate,"position":"bottom"},on:{"select":_vm.calendarConfirm},model:{value:(_vm.isCalendar),callback:function ($$v) {_vm.isCalendar=$$v},expression:"isCalendar"}}),(_vm.isAddress)?_c('select-address',{attrs:{"showPicker":_vm.isAddress,"transportation":_vm.activeIndex,"is-set-out":_vm.isSetOut},on:{"close":function($event){_vm.isAddress = false},"comfire":_vm.comfire}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark"},[_c('img',{attrs:{"src":require("@assets/mark.png"),"alt":""}})])
}]

export { render, staticRenderFns }