<template>
  <div class="nav-bar" :style="{ background: bgColor }">
    <div
      class="nav-bar-left"
      :style="{ visibility: hiddenBack ? 'hidden' : 'visible' }"
    >
      <slot name="left"
        ><img class="goback" src="@assets/home_back.png" alt="" @click="goBack"
      /></slot>
    </div>
    <slot name="title"
      ><span class="van-ellipsis"> {{ title }}</span></slot
    >
    <div class="nav-bar-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: false,
      default: ''
    },
    hiddenBack: {
      type: Boolean,
      require: false,
      default: false
    },
    bgColor: {
      type: String,
      require: false,
      default: '#f4f4f4'
    }
  },
  created() {
    console.log('navbar created', this.canGoback)
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  width: 100%;
  height: 1.92rem;
  display: flex;
  align-items: center;

  .goback {
    cursor: pointer;
  }

  > span {
    flex: 1;
    font-size: 0.74rem;
    color: #111;
    text-align: center;
  }

  .nav-bar-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 2.96rem;
    margin-left: 0.64rem;

    > img {
      width: 1.11rem;
      height: 1.11rem;
    }
  }

  .nav-bar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 2.96rem;
    margin-right: 0.64rem;
  }
}
</style>