// 接待
import reception from '@views/reception'
// 在线订票
import booking from '@views/booking/index'
// 车次列表
import queryResults from '@views/booking/queryResults'
// 订票详情
import details from '@views/booking/details'
// 手动添加
import addTicket from '@views/addTicket/index'
// 登录
import login from '@views/login/index'
// 我的
import my from '@views/my/index'
// 个人信息
import personalInformation from '@views/personalInformation/index'
// 订票记录
import bookingRecord from '@views/bookingRecord/index'


Vue.use(VueRouter)
// 处理路由跳转报错 路由版本导致错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch(err => err)
}

import store from '../store'

const routes = [
    {
        path: '/',
        name: 'reception',
        component: reception
    },
    {
        path: '/booking',
        name: 'booking',
        component: booking
    },
    {
        path: '/queryResults',
        name: 'queryResults',
        component: queryResults
    },
    {
        path: '/details',
        name: 'details',
        component: details
    },
    {
        path: '/addTicket',
        name: 'addTicket',
        component: addTicket
    },
    {
        path: '/login',
        name: 'login',
        component: login
    }, {
        path: '/my',
        name: 'my',
        component: my
    },
    {
        path: '/personalInformation',
        name: 'personalInformation',
        component: personalInformation
    },
    {
        path: '/bookingRecord',
        name: 'bookingRecord',
        component: bookingRecord
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    store.commit('user/setGuestId', localStorage.getItem('guestId') || '')
    store.commit('setProjectId', localStorage.getItem('projectId') || '')
    store.commit('setProject', JSON.parse(sessionStorage.getItem('project')) || {})
    store.commit('user/setUserInfo', JSON.parse(localStorage.getItem('userInfo')) || {})
    if (!to.query.projectCode && from.query.projectCode) {
        return next({
            ...to,
            query: {
                ...to.query,
                projectCode: from.query.projectCode,
            }
        })
    }
    let projectCode = localStorage.getItem('projectCode')
    if (to.path == '/login') {
        if (projectCode == to.query.projectCode && localStorage.getItem('token')) {
            return next({
                path: '/',
                query: {
                    ...to.query,
                    projectCode: projectCode
                }
            })
        }
    }
    next()
})
router.afterEach((to, from) => {
    store.dispatch('getprojects', {
        projectCode: to.query.projectCode || from.query.projectCode
    })
    if (!store.state.common.wxConfig) {
        store.dispatch('getWx', {
            url: window.location.href
        })
    }

})
export default router
