<template>
  <div id="app" class="wrap" :style="contentWidth">
    <div class="content-wrap">
      <loading v-show="showLoading"></loading>
      <keep-alive :include="aliveList">
        <router-view style="flex: 1; height: 0" />
      </keep-alive>
    </div>
    <tabbar class="tabbar"></tabbar>
  </div>
</template>
<script>
import tabbar from '@components/Common/Tabbar'
import Loading from '@components/Common/Loading'
import { mapState } from 'vuex'
export default {
  components: {
    tabbar,
    Loading

  },
  data() {
    return {

    }
  },
  mounted() {
    console.log(this.aliveList)
  },
  computed: {
    ...mapState(['aliveList']),
    contentWidth() {
      return {
        maxWidth: this.$store.getters.contentWidth
      }
    },
    aliveList() {
      return this.$store.state.common.aliveList
    },
    showLoading() {
      return this.$store.state.common.showLoading
    },
  }
}
</script>
<style lang="scss">
.wrap {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: #f3f5f7;
  display: flex;
  flex-direction: column;

  .content-wrap {
    flex: 1;
    height: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>
