export default {
    showLoading: true, // loading
    contentLeft: 0,
    contentRight: 0,
    contentHeight: 0,
    tabbarIndex: 0,
    projectId: '1',
    project: null,
    wxConfig: null,
    aliveList: ['booking'],
    dictsCodeMap: {}
};