var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address-time"},[_c('div',{staticClass:"address"},[_c('div',{ref:"start",class:[
        'address-item van-ellipsis text-left animate__animated',
        _vm.isChange ? 'animate__lightSpeedInRight' : '',
      ],on:{"click":function($event){return _vm.$emit('start')}}},[_vm._v(" "+_vm._s(_vm.start || "出发地")+" ")]),_c('img',{class:['animate__animated', _vm.isChange ? 'animate__rotateIn' : ''],attrs:{"src":require("@assets/xchange.png"),"alt":""},on:{"click":_vm.changeAddress}}),_c('div',{ref:"end",class:[
        'address-item van-ellipsis text-right animate__animated',
        _vm.isChange ? 'animate__lightSpeedInLeft' : '',
      ],on:{"click":function($event){return _vm.$emit('end')}}},[_vm._v(" "+_vm._s(_vm.end || "到达地")+" ")])]),_c('div',{staticClass:"time",on:{"click":function($event){return _vm.$emit('date')}}},[_c('div',{staticClass:"time-value"},[_c('span',[_vm._v(_vm._s(_vm.date)),_c('span',{staticClass:"week"},[_vm._v(_vm._s(_vm.week))])])]),_c('van-icon',{attrs:{"name":"arrow"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }